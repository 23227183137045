import styled from 'styled-components'
import React from 'react'
import { useAccount } from 'wagmi'
import { useLocation, Link } from 'react-router-dom'

import { Connect, Connected } from '@packages/web3'
import { shared } from '@packages/ui'
import Telegram from '@packages/ui/src/shared/assets/social-logos/telegram.svg'
import Twitter from '@packages/ui/src/shared/assets/social-logos/twitter.svg'
import Discord from '@packages/ui/src/shared/assets/social-logos/discord.svg'
import OpenSea from '@packages/ui/src/shared/assets/social-logos/opensea.svg'

import { LINKS } from 'src/settings'
import SmallLogoImg from 'src/shared/assets/logo.png'
import { COLORS, FONTS } from 'src/shared/constants'

import { ROUTES } from '../constants'

const { BREAKPOINTS, Z_INDEX } = shared

export function Navbar() {
  const { isConnected } = useAccount()
  const location = useLocation()

  const showSmallLogoHeader = location.pathname !== ROUTES.home && location.pathname !== ROUTES.mint

  return (
    <Container>
      <Head>
        <LeftContent>
          {showSmallLogoHeader && (
            <Link to={ROUTES.home}>
              <SmallLogo src={SmallLogoImg} />
            </Link>
          )}
          <Circle href={LINKS.telegram} target='_blank'>
            <SocialImg src={Telegram} />
          </Circle>
          <Circle href={LINKS.discord} target='_blank'>
            <SocialImg src={Discord} />
          </Circle>
          <Circle href={LINKS.twitter} target='_blank'>
            <SocialImg src={Twitter} />
          </Circle>
          <Circle href={LINKS.opensea} target='_blank'>
            <SocialImg src={OpenSea} imgWidth='35px' />
          </Circle>
        </LeftContent>

        <RightContent>
          <WalletContainer>
            {isConnected ? <ConnectedWrapper /> : <ConnectWrapper />}
          </WalletContainer>
        </RightContent>
      </Head>
    </Container>
  )
}

export function NavbarOffsetContainer({ children }: { children: React.ReactNode }) {
  return <OffsetContainer>{children}</OffsetContainer>
}

interface SocialImgProps {
  imgWidth?: string
}

const OffsetContainer = styled.div`
  z-index: ${Z_INDEX.headerLayer3};
  position: relative;
  margin-top: 2vw;
`

const Logo = styled.img`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: auto;
  height: 35px;
  @media only screen and (max-width: 623px) {
    display: none;
  }
`

const SmallLogo = styled.img`
  width: 40px;
  height: 40px;
`

const Container = styled.div`
  z-index: ${Z_INDEX.headerLayer1};
  height: 75px;
  width: 100%;
  color: white;
  display: flex;
  justify-content: center;
  position: relative;
`

const Head = styled.div`
  position: relative;
  z-index: ${Z_INDEX.content};
  padding-left: 15px;
  width: 100%;
  height: 100%;
  max-width: ${BREAKPOINTS.large};
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const LeftContent = styled.div`
  display: flex;
  gap: 10px;
  @media only screen and (max-width: ${BREAKPOINTS.medium}) {
    gap: 1px;
  }
`

const WalletContainer = styled.div``

const ConnectedWrapper = styled(Connected)`
  font-family: ${FONTS.title};
  border-radius: 5px;
  background-color: ${COLORS.inputs};
  border: 1px solid white;
`

const ConnectWrapper = styled(Connect)`
  font-family: ${FONTS.title};
  border-radius: 5px;
  background-color: ${COLORS.inputs};
  border: 1px solid white;
  button {
    background-color: ${COLORS.inputs};
    font-family: ${FONTS.title};
  }
`

const RightContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  @media only screen and (max-width: ${BREAKPOINTS.medium}) {
    gap: 10px;
  }
  padding-right: 15px;
`

const Circle = styled.a`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 100px;
  transition: 250ms;
  &:hover {
    opacity: 0.8;
  }
`

const SocialImg = styled.img<SocialImgProps>`
  width: ${({ imgWidth = '30px' }) => imgWidth};
`

const MintDetails = styled.button`
  cursor: pointer;
  background-color: #61AC2D;
  border 3px solid #9FFF00;
  border-radius: 6px;
  color: #ffffff;
  font-size: 18px;
  padding: 5px 15px;
  font-family: ${FONTS.title};
  transition: 250ms;
  &:hover {
    opacity: 0.8;
  }
`
