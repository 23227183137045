import styled, { css } from 'styled-components'

import { shared } from '@packages/ui'

import { COLORS, FONTS } from '../constants'

const { BREAKPOINTS } = shared

export const Button = styled(shared.Button)`
  cursor: pointer;
  font-size: 20px;
  border-radius: 5px;
  background-color: ${COLORS.buttons.background};
  border: 2px solid ${COLORS.buttons.border};
  font-family: ${FONTS.title};
  @media only screen and (max-width: ${BREAKPOINTS.medium}) {
    padding: 9px 15px;
  }

  ${(props) =>
    props.disabled &&
    css`
      color: ${COLORS.disabled};
      background-color: ${COLORS.buttons.disabled};
      border: 2px solid ${COLORS.buttons.disabledBorder};
    `}

  ${(props) =>
    props.border &&
    css`
      background-color: ${COLORS.background};
      color: ${COLORS.white};
      border: 2px solid ${COLORS.buttons.border};
      box-shadow: none;
    `}
`

export const ModalButton = styled(shared.Button)`
  max-width: 200px;
  color: ${COLORS.modalFont};
  font-family: ${FONTS.text};
  background-color: transparent;
  border: 2px solid ${COLORS.modalFont};
  border-radius: 0px;
  flex: 1;
  text-transform: uppercase;
  font-size: 27px;
  line-height: 27px;
  a {
    text-transform: uppercase;
    font-size: 27px;
    line-height: 27px;
  }
  @media only screen and (max-width: ${BREAKPOINTS.medium}) {
    font-size: 21px;
    padding: 10px 8px;

    div {
      width: 18px;
      height: 18px;
    }
    a {
      font-size: 21px;
    }
  }
`

export const ModalCloseButton = styled.div`
  padding-top: 5px;
  text-transform: uppercase;
  font-size: 30px;
  line-height: 30px;
  cursor: pointer;
  top: 12px;
  right: 23px;
  @media only screen and (max-width: ${BREAKPOINTS.medium}) {
    padding-top: 3px;
    font-size: 25px;
  }
`

export const ButtonWithCancel = styled(shared.ButtonWithCancel)`
  button {
    font-size: 25px;
    line-height: 27px;
    border-radius: 0px;
    background-color: ${COLORS.buttons.background};
    border: 2px solid ${COLORS.buttons.border};
    font-family: ${FONTS.text};
    text-transform: uppercase;
    gap: 8px;

    div {
      width: 23px;
      height: 23px;
    }

    @media only screen and (max-width: ${BREAKPOINTS.medium}) {
      padding: 9px 15px;
      font-size: 20px;

      div {
        width: 20px;
        height: 20px;
      }
    }
  }

  ${(props) =>
    props.canCancel &&
    css`
      button {
        &:first-child {
          background-color: transparent;
        }
        &:nth-child(2) {
          height: 59px;
          line-height: 18px;
          @media only screen and (max-width: ${BREAKPOINTS.medium}) {
            height: 48px;
          }
        }
      }
    `}
`
