import { ReCAPTCHA } from 'react-google-recaptcha'
import { useEffect, useRef } from 'react'

interface Props {
  onChange: (token: string | null) => void
  siteKey: string
  grecaptcha?: any
}

// use this hook to generate the grecaptcha object required in the ReCaptchaCheckbox.Props
export const useInstanceLoader = (siteKey: string) => {
  const helperInstance = useRef<any>(null)

  useEffect(() => {
    if (!(window as any).grecaptcha) {
      const script = document.createElement('script')
      script.src = `https://www.recaptcha.net/recaptcha/api.js?render=${siteKey}`
      script.async = true
      script.defer = true
      script.onload = () => {
        helperInstance.current = (window as any).grecaptcha
      }
      document.body.appendChild(script)
    } else {
      helperInstance.current = (window as any).grecaptcha
    }

    return () => {
      if (helperInstance.current) {
        helperInstance.current.reset()
      }
    }
  }, [])

  return helperInstance.current
}

export function ReCaptchaCheckbox({ onChange, siteKey, grecaptcha }: Props) {
  return <ReCAPTCHA sitekey={siteKey} onChange={onChange} grecaptcha={grecaptcha} />
}
