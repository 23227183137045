import { ethers } from 'ethers'
import { useNetwork, useAccount } from 'wagmi'

import { useEthersProvider, useEthersSigner } from './adapters'

type ContractType = [ethers.Contract | null, boolean]
type AddressMap = { [address: string]: ContractType }

const nullish: ContractType = [null, false]
const contractSignerCachePerChainId = new Map<number, AddressMap>()
const contractProviderCachePerChainId = new Map<number, AddressMap>()

function getContracts(chainId: number, cache: Map<number, AddressMap>): AddressMap {
  let addressMap: AddressMap = {}
  if (!cache.has(chainId)) {
    cache.set(chainId, addressMap)
  } else {
    const cached = cache.get(chainId)
    if (cached) {
      addressMap = cached
    }
  }

  return addressMap
}

export function useContract({
  address,
  abi,
  requireSigner = false,
  deployedChainId,
}: {
  address?: string
  abi: any
  requireSigner?: boolean
  deployedChainId: number
}): ContractType {
  address = address?.toLowerCase()

  const { chain } = useNetwork()
  const { isConnected } = useAccount()
  const provider = useEthersProvider({ chainId: deployedChainId })
  const signer = useEthersSigner({ chainId: deployedChainId })

  if (
    !address ||
    address === '0x0000000000000000000000000000000000000000' ||
    (requireSigner && !signer)
  ) {
    return nullish
  }

  let contractCache: AddressMap = {}

  if (signer && chain?.id === deployedChainId && isConnected) {
    contractCache = getContracts(deployedChainId, contractSignerCachePerChainId)
    if (!contractCache[address]) {
      contractCache[address] = [new ethers.Contract(address, abi, signer), true]
    }
  } else if (provider) {
    contractCache = getContracts(deployedChainId, contractProviderCachePerChainId)
    if (!contractCache[address]) {
      contractCache[address] = [new ethers.Contract(address, abi, provider), true]
    }
  }

  return contractCache?.[address] ?? nullish
}
