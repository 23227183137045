import styled, { css } from 'styled-components'

import { StyledComponentProps } from '../interfaces'

interface Props extends StyledComponentProps {
  checked: boolean
  onChange: () => void
  label?: string
}

export function Checkbox({ checked, onChange, label, className }: Props) {
  return (
    <Container className={className}>
      <Input id='checkbox' type='checkbox' checked={checked} onChange={onChange} />
      <Label htmlFor='checkbox'>
        <CheckContainer checked={checked}>
          <svg width='12px' height='10px'>
            <use xlinkHref='#check'></use>
          </svg>
        </CheckContainer>
        <LabelText>{label}</LabelText>
      </Label>
      <InlineSvg>
        <symbol id='check' viewBox='0 0 12 10'>
          <polyline points='1.5 6 4.5 9 10.5 1'></polyline>
        </symbol>
      </InlineSvg>
    </Container>
  )
}

const Input = styled.input`
  position: absolute;
  visibility: hidden;
`

const Label = styled.label`
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  padding: 10px;
  border-radius: 6px;
  overflow: hidden;
  transition: all 0.2s ease;
  display: inline-block;

  &:hover {
    background: rgba(0, 119, 255, 0.06);
  }
  &:hover span:first-child {
    border-color: #07f;
  }
  @media screen and (max-width: 640px) {
    width: 100%;
    display: inline-block;
  }
`

const CheckContainer = styled.span<{ checked: boolean }>`
  float: left;
  vertical-align: middle;
  transform: translate3d(0, 0, 0);
  position: relative;
  width: 18px;
  height: 18px;
  border-radius: 4px;
  transform: scale(1);
  border: 2px solid white;
  transition: all 0.2s ease;
  svg {
    position: absolute;
    top: 2px;
    left: 1px;
    fill: none;
    stroke: #fff;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-dasharray: 16px;
    stroke-dashoffset: 16px;
    transition: all 0.3s ease;
    transition-delay: 0.1s;
    transform: translate3d(0, 0, 0);
  }
  ${(props) =>
    props.checked &&
    css`
      background: #07f;
      border-color: #07f;
      animation: wave-4 0.4s ease;
      svg {
        stroke-dashoffset: 0;
      }
    `}
`

const LabelText = styled.span`
  float: left;
  vertical-align: middle;
  transform: translate3d(0, 0, 0);
  padding-left: 8px;
  line-height: 18px;
`

const InlineSvg = styled.svg`
  position: absolute;
  width: 0;
  height: 0;
  pointer-events: none;
  user-select: none;
`

const Container = styled.div`
  @-moz-keyframes wave-4 {
    50% {
      transform: scale(0.9);
    }
  }
  @-webkit-keyframes wave-4 {
    50% {
      transform: scale(0.9);
    }
  }
  @-o-keyframes wave-4 {
    50% {
      transform: scale(0.9);
    }
  }
  @keyframes wave-4 {
    50% {
      transform: scale(0.9);
    }
  }
`
