type Optional<T> = T | undefined | null

function getEnv(key: string): string
function getEnv<T extends Optional<string>>(key: string, defaultValue: T): string | T
function getEnv(key: string, defaultValue?: Optional<string>): Optional<string> {
  const value = process.env[key]
  if (value === undefined) {
    if (typeof defaultValue === 'string') {
      return defaultValue
    } else if (defaultValue == null && arguments.length === 2) {
      return defaultValue
    }
    throw new RequiredEnvError(key)
  }
  return value
}

function getBoolEnv(key: string): boolean
function getBoolEnv<T extends Optional<boolean>>(key: string, defaultValue: T): boolean | T
function getBoolEnv(key: string, defaultValue?: Optional<boolean>): Optional<boolean> {
  // eslint-disable-next-line @typescript-eslint/quotes
  const typeDescription = "a boolean ('true' or 'false')"
  if (process.env[key] === undefined) {
    if (typeof defaultValue === 'boolean') {
      return defaultValue
    } else if (defaultValue == null && arguments.length === 2) {
      return undefined
    }
    throw new RequiredEnvError(key, typeDescription)
  }
  const value = (process.env[key] || '').trim().toLowerCase()
  if (['true', 'false'].indexOf(value) === -1) {
    throw new EnvParseError(key, value, typeDescription)
  }
  return value === 'true'
}

function getIntEnv(key: string): number
function getIntEnv<T extends Optional<number>>(key: string, defaultValue: T): number | T
function getIntEnv(key: string, defaultValue?: Optional<number>): Optional<number> {
  const typeDescription = 'an integer'
  if (process.env[key] === undefined) {
    if (typeof defaultValue === 'number') {
      return defaultValue
    } else if (defaultValue == null && arguments.length === 2) {
      return undefined
    }
    throw new RequiredEnvError(key, typeDescription)
  }
  const value = process.env[key] || ''
  const int: number | undefined = parseInt(value, 10)
  if (isNaN(int)) {
    throw new EnvParseError(key, value, typeDescription)
  }
  return int
}

class EnvParseError extends Error {
  constructor(key: string, value: string, typeDescription: string) {
    super(
      `Environment variable '${key}' with value '${value}' could not be parsed as ${typeDescription}.`
    )
    Object.setPrototypeOf(this, EnvParseError.prototype)
  }
}

class RequiredEnvError extends Error {
  constructor(key: string, typeDescription?: string) {
    let ending = '.'
    if (typeDescription) {
      ending = `, should be ${typeDescription}.`
    }
    super(`Required environment variable '${key}' is unset${ending}`)
    Object.setPrototypeOf(this, RequiredEnvError.prototype)
  }
}

export { getEnv, getBoolEnv, getIntEnv, EnvParseError, RequiredEnvError }
