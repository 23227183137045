export const COLORS = {
  background: '#111420',
  logo: '#eb5c23',
  inputs: '#1d202b',
  black: 'black',
  white: 'white',
  error: 'red',
  disabled: '#c1bbbb',
  buttons: {
    background: '#61ac2d',
    border: '#9fff00',
    disabled: '#615e5e',
    disabledBorder: '#363535',
  },
  modalFont: '#3f9900',
}
