import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { shared } from '@packages/ui'

import { ROUTES } from '../constants'

export function ScrollToTop() {
  const { pathname, hash } = useLocation()
  const location = useLocation()
  const prevLocation = shared.usePreviousLocation(location)

  useEffect(() => {
    if (hash) {
      shared.scrollToHash(hash)
    } else if (pathname !== ROUTES.mint && prevLocation.pathname !== ROUTES.mint) {
      window.scrollTo(0, 0)
    }
  }, [pathname, hash])

  return null
}
