import { useWeb3Modal } from '@web3modal/react'
import { useAccount, useNetwork, useSwitchNetwork, useBlockNumber } from 'wagmi'
import { useEffect, useState } from 'react'
import { ContractTransactionResponse, formatEther } from 'ethers'
import styled, { css } from 'styled-components/macro'
import ReactModal from 'react-modal'
import { useNavigate } from 'react-router-dom'

import {
  CompletedOrder,
  NFT,
  SignatureData,
  UpdateImageAttestationPayload,
  UpdateImageAttestationRequest,
} from '@packages/interfaces'
import { api, shared, nfts } from '@packages/ui'
import { parseBlockchainError, useContract } from '@packages/web3'

import {
  API_URL,
  DEPLOYED_CHAIN_ID,
  DOGELON_TOKEN_CONTRACT_ADDRESS,
  ERC721_CONTRACT_ADDRESS,
  ERC721_MINTER_CONTRACT_ADDRESS,
  RECAPTCHA_V2_SITE_KEY,
} from 'src/settings'
import erc20Abi from 'src/web3/abis/ERC20.json'
import erc721MinterAbi from 'src/web3/abis/ERC721DogelonMinter.json'
import erc721Abi from 'src/web3/abis/ERC721Dogelon.json'
import { COLORS, FONTS } from 'src/shared/constants'
import {
  ButtonWithCancel,
  ModalButton,
  ModalCloseButton,
  ModalElementWrapper,
  TransactionError,
  innerWideModalScreenStyles,
} from 'src/shared/components'
import { ROUTES } from 'src/pages'
import { OrderErrorMessage } from 'src/orders'
import { API_ENDPOINT } from 'src/apiEndpoints'

const { BREAKPOINTS, Z_INDEX } = shared

type Phase =
  | 'confirming'
  | 'connecting'
  | 'switching-network'
  | 'recaptcha'
  | 'approving'
  | 'waiting-approval'
  | 'attestation'
  | 'signing'
  | 'updating'
  | 'tx-error'
  | 'error'
  | 'success'

interface Props extends shared.ModalProps {
  selectedNFT: NFT
  updateImageResponse: CompletedOrder
}

export function UpdateImageModal({
  isModalOpen,
  closeModal,
  selectedNFT,
  updateImageResponse,
}: Props) {
  const navigate = useNavigate()
  const { width } = shared.useWindowDimensions()

  const grecaptcha = api.useInstanceLoader(RECAPTCHA_V2_SITE_KEY)
  const [displayReCaptchaV2Checkbox, setDisplayReCaptchaV2Checkbox] = useState(false)
  const fetchWithReCaptcha = api.useFetchWithReCaptcha('signature')

  const [baseUpdateImagePrice, setBaseUpdateImagePrice] = useState<bigint>()
  const [imageUpdatesCount, setImageUpdatesCount] = useState<number>(1)
  const [signatureData, setSignatureData] = useState<SignatureData>()

  const [tx, setTx] = useState<ContractTransactionResponse>()
  const [txError, setTxError] = useState<string>()
  const [error, setError] = useState<api.ApiErrorResponse>()
  const [phase, setPhase] = useState<Phase>('confirming')

  const { open } = useWeb3Modal()
  const { address } = useAccount()
  const { chain } = useNetwork()
  const { data: blockNumber } = useBlockNumber()
  const { switchNetwork } = useSwitchNetwork({
    onError() {
      setPhase('confirming')
    },
  })

  const [dogelonTokenContract] = useContract({
    address: DOGELON_TOKEN_CONTRACT_ADDRESS,
    abi: erc20Abi,
    requireSigner: true,
    deployedChainId: DEPLOYED_CHAIN_ID,
  })

  const [erc721Contract] = useContract({
    address: ERC721_CONTRACT_ADDRESS,
    abi: erc721Abi,
    requireSigner: true,
    deployedChainId: DEPLOYED_CHAIN_ID,
  })

  const [erc721MinterContract] = useContract({
    address: ERC721_MINTER_CONTRACT_ADDRESS,
    abi: erc721MinterAbi,
    requireSigner: true,
    deployedChainId: DEPLOYED_CHAIN_ID,
  })

  useEffect(() => {
    if (
      (phase === 'connecting' || phase === 'switching-network' || phase === 'attestation') &&
      address &&
      dogelonTokenContract &&
      erc721Contract &&
      erc721MinterContract
    ) {
      updateNFTImage()
    }
  }, [address, dogelonTokenContract, erc721Contract, erc721MinterContract])

  useEffect(() => {
    if (erc721Contract) {
      if (!baseUpdateImagePrice) {
        erc721Contract.imageUpdateBasePrice().then((imageUpdateBasePrice: bigint) => {
          setBaseUpdateImagePrice(imageUpdateBasePrice)
        })
      }
      if (address) {
        erc721Contract.imageUpdates(address).then((imageUpdates: bigint) => {
          setImageUpdatesCount(Number(imageUpdates))
        })
      }
    }
  }, [blockNumber, erc721Contract, address])

  function resetData() {
    setPhase('confirming')
    setSignatureData(undefined)
    setError(error)
    setTx(undefined)
    setTxError(undefined)
  }

  function handleClose() {
    resetData()
    closeModal()
    navigate(ROUTES.updateImage)
  }

  async function setErrorPhase(error: api.ApiErrorResponse) {
    setError(error)
    setPhase('error')
  }

  async function setTxErrorPhase(error: any) {
    const parsedError = parseBlockchainError(error)
    setTxError(parsedError)
    setPhase('tx-error')
  }

  function handleTryAgainOnError() {
    resetData()
  }

  async function onReCaptchaV2CheckboxChange(token: string | null) {
    if (token) {
      setDisplayReCaptchaV2Checkbox(false)
      setPhase('signing')
      // delay submission so that we dont get rate limited
      await new Promise((r) => setTimeout(r, 2000))
      await updateNFTImage(token)
    }
  }

  async function updateNFTImage(reCaptchaV2Token?: string) {
    if (
      !(
        phase === 'confirming' ||
        phase === 'connecting' ||
        phase === 'switching-network' ||
        phase === 'approving' ||
        phase === 'waiting-approval' ||
        phase === 'attestation' ||
        phase === 'signing'
      )
    )
      return

    if (!address) {
      open()
      setPhase('connecting')
      return
    }

    if (chain?.id !== DEPLOYED_CHAIN_ID && switchNetwork) {
      try {
        await switchNetwork(DEPLOYED_CHAIN_ID)
        setPhase('switching-network')
        return
      } catch (error) {
        console.error(error)
        setErrorPhase({ name: 'UnknownError' })
        return
      }
    }

    if (!dogelonTokenContract || !erc721Contract || !erc721MinterContract) {
      // contract object isnt created yet, will set phase to connecting
      // and useEffect above will recall handleMint once the contract object is set
      setPhase('connecting')
      return
    }

    let approvalTx
    setPhase('approving')
    try {
      const imageUpdates: bigint = await erc721Contract.imageUpdates(selectedNFT.tokenId)
      const imageUpdateBasePrice: bigint = await erc721Contract.imageUpdateBasePrice()
      const imageUpdatePrice: bigint = (imageUpdates + BigInt(1)) * imageUpdateBasePrice
      const allowance: bigint = await dogelonTokenContract.allowance(
        address,
        ERC721_CONTRACT_ADDRESS
      )
      if (imageUpdatePrice > allowance) {
        approvalTx = await dogelonTokenContract.approve(ERC721_CONTRACT_ADDRESS, imageUpdatePrice)
        setPhase('waiting-approval')
        await approvalTx.wait()
      }
    } catch (error: any) {
      console.error(error)
      setTxErrorPhase(error)
      return
    }

    let data = signatureData
    if (!data && selectedNFT.imageHash) {
      setPhase('attestation')
      try {
        const request: UpdateImageAttestationRequest = {
          address,
          newImageHash: updateImageResponse.finalImageHash,
          orderId: updateImageResponse.id,
          tokenId: selectedNFT.tokenId,
        }

        const response = await fetchWithReCaptcha(
          API_URL + API_ENDPOINT.POST.updateImageSignature,
          request,
          reCaptchaV2Token
        )
        const json = await response.json()

        if (response.status === 200) {
          data = json
        } else if (json && api.isApiErrorResponse(json)) {
          if (json.name === 'ReCaptchaV3FailedError') {
            setDisplayReCaptchaV2Checkbox(true)
            setPhase('recaptcha')
          } else {
            setErrorPhase(json)
          }
          return
        } else {
          throw new Error(json)
        }
      } catch (error: any) {
        console.error(error)
        setErrorPhase({ name: 'UnknownError' })
        return
      }
    }

    let _tx: ContractTransactionResponse
    setPhase('signing')
    try {
      console.debug('signing', selectedNFT, data?.message)
      _tx = await erc721MinterContract.updateImage(
        data?.message.timestamp,
        (data?.message as UpdateImageAttestationPayload).newImageHash,
        selectedNFT.tokenId,
        data?.signature
      )
    } catch (error: any) {
      console.error(error)
      setTxErrorPhase(error)
      return
    }

    setTx(_tx)
    setPhase('updating')
    try {
      const receipt = await _tx.wait()
    } catch (error: any) {
      console.error(error)
      if (error.code === 'TRANSACTION_REPLACED' && error.reason === 'repriced') {
        setTx(error.replacement)
      } else {
        console.error(error)
        setTxErrorPhase(error)
        return
      }
    }
    setPhase('success')
  }

  function handleCancel() {
    resetData()
  }

  const isBurnButtonDisabled = !selectedNFT

  const canClose =
    phase === 'confirming' ||
    phase === 'connecting' ||
    phase === 'switching-network' ||
    phase === 'recaptcha' ||
    phase === 'approving' ||
    phase === 'success' ||
    phase === 'error' ||
    phase === 'tx-error'

  const canCancel =
    phase === 'connecting' ||
    phase === 'switching-network' ||
    phase === 'approving' ||
    phase === 'recaptcha' ||
    phase === 'signing'

  let buttonText
  switch (phase) {
    case 'connecting':
    case 'switching-network':
    case 'signing':
    case 'approving':
      buttonText = 'See Wallet'
      break
    case 'waiting-approval':
      buttonText = 'Waiting for Confirmations'
      break
    case 'recaptcha':
      buttonText = 'ReCAPTCHA'
      break
    default:
      buttonText = 'Confirm'
  }

  const beforeAfterThumbnails = (
    <Thumbnails>
      <ThumbnailWrapper
        nft={{ ...selectedNFT, title: 'Before' }}
        width={width > parseInt(BREAKPOINTS.medium) ? 180 : 95}
        onClick={() => {}}
        key={selectedNFT.tokenId}
      />
      <ArrowContainer>⮕</ArrowContainer>
      <ThumbnailWrapper
        nft={{
          tokenId: 1,
          title: 'After',
          imageHash: updateImageResponse.finalImageHash,
          imageUrl: updateImageResponse.uncompressedImageUrl,
          imageThumbnailUrl: updateImageResponse.uncompressedImageUrl,
          address: ERC721_CONTRACT_ADDRESS,
        }}
        width={width > parseInt(BREAKPOINTS.medium) ? 180 : 95}
        onClick={() => {}}
        key='after-dogelon-image'
      />
    </Thumbnails>
  )

  return (
    <ReactModal
      isOpen={isModalOpen}
      onRequestClose={handleClose}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
      ariaHideApp={false}
      className='modalElement'
      contentElement={(props, children) => (
        <ModalElementWrapper {...props}>{children}</ModalElementWrapper>
      )}
      overlayElement={(props, contentElement) => {
        return (
          <shared.ModalPortal {...props}>
            <shared.OverlayElement className='modelOverlay'>{contentElement}</shared.OverlayElement>
          </shared.ModalPortal>
        )
      }}
    >
      <shared.ModalContainer>
        <ModalContainer>
          {(phase === 'connecting' ||
            phase === 'switching-network' ||
            phase === 'confirming' ||
            phase === 'approving' ||
            phase === 'waiting-approval' ||
            phase === 'recaptcha' ||
            phase === 'signing') && (
            <ThumbnailContainer>
              <Title>Update Generation</Title>
              <Description>
                This action will require{' '}
                {formatEther(
                  baseUpdateImagePrice
                    ? baseUpdateImagePrice * BigInt(imageUpdatesCount > 0 ? imageUpdatesCount : 1)
                    : BigInt(0)
                )}{' '}
                $ELON to be burned. Each subsequent update will cost more tokens
              </Description>
              {beforeAfterThumbnails}
              {displayReCaptchaV2Checkbox && (
                <api.ReCaptchaCheckbox
                  onChange={onReCaptchaV2CheckboxChange}
                  siteKey={RECAPTCHA_V2_SITE_KEY}
                  grecaptcha={grecaptcha}
                />
              )}
              <ButtonsContainer>
                {phase !== 'waiting-approval' ? (
                  <>
                    {!canCancel && (
                      <RedoButtonContainer>
                        <ModalButton onClick={handleClose}>Redo</ModalButton>
                      </RedoButtonContainer>
                    )}
                    <ButtonWithCancel
                      canCancel={canCancel}
                      onCancel={handleCancel}
                      onClick={() => updateNFTImage()} // need wrapper as it keeps calling updateNFTImage with the button as the first arg
                      disabled={isBurnButtonDisabled}
                      cancelText='X'
                    >
                      {buttonText}
                    </ButtonWithCancel>
                  </>
                ) : (
                  <LoadingButton loading disabled showTextOnLoading>
                    {buttonText}
                  </LoadingButton>
                )}
              </ButtonsContainer>
              {canClose && <ModalCloseButton onClick={handleClose}>EXIT</ModalCloseButton>}
            </ThumbnailContainer>
          )}

          {phase === 'updating' && (
            <Container>
              <Title>Updating</Title>
              <ImgLoadingContainer>{beforeAfterThumbnails}</ImgLoadingContainer>
              <P center>Please wait while the transaction confirms.</P>
            </Container>
          )}

          {phase === 'success' && (
            <Container>
              <Title>Updated!</Title>
              {beforeAfterThumbnails}
              <ButtonsContainer>
                <RedoButtonContainer>
                  <ModalButton onClick={handleClose}>Redo</ModalButton>
                </RedoButtonContainer>
                <Link
                  href={nfts.getOpenSeaNFTUrl(
                    chain?.id ?? 1,
                    selectedNFT?.tokenId ?? 0,
                    ERC721_CONTRACT_ADDRESS
                  )}
                  target='_blank'
                  rel='noreferrer'
                >
                  <ModalButton>OpenSea</ModalButton>
                </Link>
              </ButtonsContainer>
              <ButtonsContainer>
                <ModalCloseButton onClick={handleClose}>EXIT</ModalCloseButton>
              </ButtonsContainer>
            </Container>
          )}

          {phase === 'error' && (
            <Container>
              {error ? (
                <OrderErrorMessage error={error} onTryAgain={handleTryAgainOnError} />
              ) : (
                <div>Error</div>
              )}
              {canClose && <ModalCloseButton onClick={handleClose}>EXIT</ModalCloseButton>}
            </Container>
          )}

          {phase === 'tx-error' && (
            <Container>
              <TransactionError txError={txError} tx={tx} onTryAgain={handleTryAgainOnError} />
              {canClose && <ModalCloseButton onClick={handleClose}>EXIT</ModalCloseButton>}
            </Container>
          )}
        </ModalContainer>
      </shared.ModalContainer>
    </ReactModal>
  )
}

const ModalContainer = styled.div`
  color: ${COLORS.modalFont};
  font-family: ${FONTS.text};
  background-color: ${COLORS.black};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 10px;
  padding-top: 20px;
  @media only screen and (max-width: ${BREAKPOINTS.medium}) {
    padding-top: 8px;
    padding-bottom: 12px;
  }
  color: ${COLORS.modalFont};
  font-family: ${FONTS.text};
  background-color: ${COLORS.black};
  ${innerWideModalScreenStyles}
`

const ThumbnailContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`

const ThumbnailWrapper = styled(nfts.NFTThumbnail)`
  cursor: default;
  z-index: ${Z_INDEX.content};
  padding: 5px 10px;
  ${(props) =>
    props.selected &&
    css`
      border: 3px solid ${COLORS.logo};
    `}
  @media only screen and (max-width: ${BREAKPOINTS.medium}) {
    padding: 5px;
  }

  div {
    border-radius: 10px;
    font-size: 22px;
    @media only screen and (max-width: ${BREAKPOINTS.medium}) {
      font-size: 19px;
    }
  }
  img {
    border-radius: 6px;
  }
`

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 10px;
  justify-content: space-between;
  flex: 1;
`

const Title = styled(shared.Title)`
  text-align: center;
  font-size: 40px;
  line-height: 38px;
  font-family: ${FONTS.text};
  @media only screen and (max-width: ${BREAKPOINTS.medium}) {
    font-size: 30px;
    line-height: 30px;
  }
`

const Description = styled.div`
  color: ${COLORS.white};
  font-family: ${FONTS.title};
  font-size: 17px;
  line-height: 20px;
  margin-top: 5px;
`

const RedoButtonContainer = styled.div`
  flex: 1;
  display: flex;
  button {
    flex: 1;
  }
`

const LoadingButton = styled(ModalButton)`
  font-size: 20px;
  max-width: 275px;
  line-height: 20px;
  padding: 20px 10px;
  gap: 10px;

  @media only screen and (max-width: ${BREAKPOINTS.small}) {
    padding: 10px 8px;
  }
`

const ImgLoadingContainer = styled.div`
  ${shared.getImageLoadingAnimation(
    COLORS.modalFont,
    COLORS.buttons.border,
    COLORS.buttons.background
  )}
  &::after {
    top: 3%;
    z-index: ${Z_INDEX.content};
  }
`

const Thumbnails = styled.div`
  z-index: ${Z_INDEX.priorityContent};
  color: ${COLORS.modalFont};
  font-family: ${FONTS.text};
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 5px;
  max-height: 375px;
  overflow: scroll;
  padding: 19px;
  @media only screen and (max-width: ${BREAKPOINTS.medium}) {
    max-height: 300px;
    padding: 5px;
    padding-bottom: 0px;
  }
  overflow-y: auto;
  overflow-x: hidden;
`

const Link = styled(shared.A)`
  flex: 1;
  width: 100%;
  button {
    width: 100%;
    color: ${COLORS.white};
  }
`

const ArrowContainer = styled.div`
  font-size: 24px;
  padding-bottom: 25px;
  @media only screen and (max-width: ${BREAKPOINTS.medium}) {
    font-size: 16px;
  }
`

const P = styled.p<{ bold?: boolean; center?: boolean }>`
  width: 100%;
  font-size: 22px;
  line-height: 20px;
  ${({ bold }) => (bold ? 'font-weight: bold' : '')};
  ${({ center }) => (center ? 'text-align: center' : '')};
`

const ButtonsContainer = styled.div`
  gap: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 400px;
  @media only screen and (max-width: ${BREAKPOINTS.medium}) {
    gap: 10px;
  }
`
