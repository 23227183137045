import styled, { css } from 'styled-components/macro'
import { useAccount } from 'wagmi'
import { useNavigate } from 'react-router-dom'
import { useState, useRef } from 'react'

import { shared } from '@packages/ui'

import { UserPromptForm, useERC721ContractState } from 'src/orders'
import {
  LINKS,
  MINT_STARTED,
  MINT_LAUNCH_UNIX_MS,
  ERC721_CONTRACT_ADDRESS,
  API_URL,
} from 'src/settings'
import { COLORS, FONTS } from 'src/shared/constants'
import { Button } from 'src/shared/components'

import { MintCountdown, NavbarOffsetContainer } from './components'
import DogelonVideo from './assets/dogelon.mp4'
import SceneImage from './assets/home-page-scene.png'
import { ROUTES } from './constants'
import LogoImg from '../shared/assets/title-logo.png'

const { BREAKPOINTS, Z_INDEX } = shared

export const HomePage = () => {
  const navigate = useNavigate()
  const { address } = useAccount()
  const { mintingEnabled, startTime } = useERC721ContractState()

  const [message, setMessage] = useState<string | null>(null)
  const [messageStatus, setMessageStatus] = useState<'success' | 'error' | null>(null)
  const addressInput = useRef<HTMLInputElement | null>(null)

  const checkWhitelisted = async () => {
    try {
      if (addressInput.current) {
        const address = addressInput.current.value
        const requestURL = `${API_URL}/v1/nftWhitelist/proof?contract=${ERC721_CONTRACT_ADDRESS}&type=concurrent&address=${address}`

        const response = await fetch(requestURL)

        if (response.status === 404) {
          setMessage('Wallet is not on allowlist.')
          setMessageStatus('error')
        } else if (response.status === 200) {
          setMessage('Wallet is on allowlist!')
          setMessageStatus('success')
        } else {
          setMessage('Please enter an address.')
          setMessageStatus('error')
        }
      }
    } catch (error) {
      console.error('Error checking allowlist:', error)
      setMessage('Error checking the allowlist. Please try again later.')
      setMessageStatus('error')
    }
  }

  let content
  if (mintingEnabled || (mintingEnabled == null && MINT_STARTED)) {
    content = (
      <ContentContainer>
        <UserPromptContainer>
          <UserPromptForm />
        </UserPromptContainer>
      </ContentContainer>
    )
  } else {
    if (!MINT_STARTED) {
      content = (
        <>
          <VideoContainer>
            <Logo src={LogoImg} />
            <VideoWrapper src={DogelonVideo} autoPlay />
          </VideoContainer>
          <MintCountdown mintLaunchUnixMs={startTime ? startTime * 1000 : MINT_LAUNCH_UNIX_MS} />
          <WhitelistChecker>
            <input ref={addressInput} placeholder='Enter your wallet address' />
            {message && messageStatus && <Message status={messageStatus}>{message}</Message>}
            <button onClick={checkWhitelisted}>Check Allowlist</button>
          </WhitelistChecker>
        </>
      )
    } else {
      content = (
        <ContentContainer>
          <AnnouncementContainer>
            <Title>Minting Period Ended</Title>
            The minting period for DogelonMars is now over. You can still buy them on{' '}
            <Link href={LINKS.opensea} target='_blank'>
              OpenSea
            </Link>
          </AnnouncementContainer>
          <UserPromptContainer announcementDisplayed>
            <UserPromptForm announcementDisplayed />
            {address && (
              <UpdateImageButton onClick={() => navigate(ROUTES.updateImage)}>
                reconstruct generations
              </UpdateImageButton>
            )}
          </UserPromptContainer>
        </ContentContainer>
      )
    }
  }

  return <HomePageWrapper>{content}</HomePageWrapper>
}

interface Props {
  children: React.ReactNode
}

export function HomePageWrapper({ children }: Props) {
  return (
    <>
      <HomePageHead className='home-page-header' height={HEADER_HEIGHT} />
      <NavbarOffsetContainer>
        {children}
        <Image src={SceneImage} />
      </NavbarOffsetContainer>
    </>
  )
}

const HEADER_HEIGHT = '40vw'

const HomePageHead = styled.div<{ height: string }>`
  z-index: ${Z_INDEX.headerLayer3};
  position: absolute;
  height: ${(props) => props.height};
  width: 100%;
  height: 100vh;
  background-size: 100vw;
  background-position: bottom;
`

const Link = styled(shared.A)`
  color: ${COLORS.modalFont};
`

const Logo = styled.img`
  width: auto;
  height: 35px;
  margin-bottom: 15px;
  @media only screen and (min-width: 623px) {
    display: none;
  }
`

const VideoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const VideoWrapper = styled(shared.Video)`
  width: 75%;
  height: 100%;
  max-height: 500px;
`

const AnnouncementContainer = styled.div`
  padding: 20px;
  @media only screen and (max-width: ${BREAKPOINTS.medium}) {
    padding-top: 15px;
    padding: 10px;
    padding-bottom: 5px;
  }
`

const UpdateImageButton = styled(Button)`
  width: 275px;
  height: 50px;
  background-color: ${COLORS.background};
`

const ContentContainer = styled.div`
  color: ${COLORS.white};
  font-family: ${FONTS.title};
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  font-size: 20px;
  @media only screen and (max-width: ${BREAKPOINTS.medium}) {
    font-size: 18px;
  }
`

const UserPromptContainer = styled.div<{ announcementDisplayed?: boolean }>`
  width: 100%;
  padding: 0px 20px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media only screen and (max-width: ${BREAKPOINTS.medium}) {
    margin-top: 20px;
  }
  ${(props) =>
    !props.announcementDisplayed &&
    css`
      margin-top: 30px;
    `}
`

const Title = styled(shared.Title)`
  color: ${COLORS.white};
  font-family: ${FONTS.title};
`

const Image = styled.img`
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: ${Z_INDEX.background};
  width: 30%;
  max-width: 700px;

  @media only screen and (max-width: ${BREAKPOINTS.medium}) {
    width: 50%;
    max-width: 400px;
  }
`

const WhitelistChecker = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  margin-top: 20px;
  & input {
    max-width: 600px;
    width: 90%;
    border-radius: 10px;
    background-color: rgb(29, 32, 43);
    border: 1px solid ${COLORS.white};
    color: ${COLORS.white};
    padding: 14px 20px 14px 20px;
    font-family: ${FONTS.title};
    font-size: 16px;
  }
  & button {
    cursor: pointer;
    background-color: #61AC2D;
    border 3px solid #9FFF00;
    border-radius: 6px;
    color: #ffffff;
    font-size: 18px;
    padding: 5px 15px;
    font-family: ${FONTS.title};
    transition: 250ms;
    margin-bottom: 150px;
    &:hover {
      opacity: 0.8;
    }
  }
`

const Message = styled.div<{ status: 'success' | 'error' }>`
  color: ${(props) => (props.status === 'success' ? 'green' : 'red')};
  margin-top: 8px;
  margin-bottom: 15px;
  font-size: 21px;
  font-family: ${FONTS.text};
`
