import ReactModal from 'react-modal'
import styled from 'styled-components/macro'
import { useState } from 'react'

import { api, shared } from '@packages/ui'

import { API_URL, RECAPTCHA_V2_SITE_KEY } from 'src/settings'
import { COLORS, FONTS } from 'src/shared/constants'
import {
  ModalButton,
  WideModalElementWrapper,
  innerWideModalScreenStyles,
} from 'src/shared/components'

const { BREAKPOINTS } = shared

interface Props extends shared.ModalProps {
  apiEndpoint: string
  onError: (error: api.ApiErrorResponse) => void
}

export function CancelOrderModal({ apiEndpoint, onError, isModalOpen, closeModal }: Props) {
  const grecaptcha = api.useInstanceLoader(RECAPTCHA_V2_SITE_KEY)
  const userId = api.useUserId()
  const [displayReCaptchaV2Checkbox, setDisplayReCaptchaV2Checkbox] = useState(false)
  const [isCancellingOrder, setIsCancellingOrder] = useState(false)
  const [orderCancelled, setIsOrderCancelled] = useState(false)
  const fetchWithReCaptcha = api.useFetchWithReCaptcha('order')

  async function onReCaptchaV2CheckboxChange(token: string | null) {
    if (token) {
      setDisplayReCaptchaV2Checkbox(false)

      await cancelOrder()
    }
  }

  function handleClose() {
    resetData()
    closeModal()
  }

  function resetData() {
    setDisplayReCaptchaV2Checkbox(false)
    setIsCancellingOrder(false)
    setIsOrderCancelled(false)
  }

  async function cancelOrder() {
    try {
      setIsCancellingOrder(true)

      const response = await fetchWithReCaptcha(`${API_URL}${apiEndpoint}/${userId}`, {})
      const json = await response.json()

      if (response.status === 200) {
        // artificial delay for cancel animation
        await new Promise((r) => setTimeout(r, 1000))
        setIsOrderCancelled(true)
        return
      } else if (json && api.isApiErrorResponse(json)) {
        if (json.name === 'ReCaptchaV3FailedError') {
          setDisplayReCaptchaV2Checkbox(true)
        } else {
          onError(json)
        }
      } else {
        throw new Error(json)
      }
    } catch (error) {
      console.error(error)
      onError({ name: 'UnknownError' })
    } finally {
      setIsCancellingOrder(false)
    }
  }

  let title = 'Transmission Already In Progress'
  let description =
    'Please either cancel your current transmission or wait for it to finish before sending another.'
  if (orderCancelled) {
    title = 'Transmission Cancelled'
    description =
      'Your transmission was successfully cancelled! You can now close this modal and submit a send a new one'
  } else if (isCancellingOrder) {
    title = 'Cancelling Transmission'
    description = 'Please wait while we cancel your transmission'
  }

  return (
    <ReactModal
      isOpen={isModalOpen}
      onRequestClose={handleClose}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
      ariaHideApp={false}
      className='modalElement'
      contentElement={(props, children) => (
        <WideModalElementWrapper {...props}>{children}</WideModalElementWrapper>
      )}
      overlayElement={(props, contentElement) => (
        <shared.ModalPortal {...props}>
          <shared.OverlayElement className='modelOverlay'>{contentElement}</shared.OverlayElement>
        </shared.ModalPortal>
      )}
    >
      <Container>
        <Title>{title}</Title>
        {description}
        {displayReCaptchaV2Checkbox && (
          <api.ReCaptchaCheckbox
            onChange={onReCaptchaV2CheckboxChange}
            siteKey={RECAPTCHA_V2_SITE_KEY}
            grecaptcha={grecaptcha}
          />
        )}
        {!orderCancelled && (
          <CancelButton onClick={cancelOrder} loading={isCancellingOrder}>
            Cancel current transmission
          </CancelButton>
        )}
        <CloseButton onClick={handleClose}>exit</CloseButton>
      </Container>
    </ReactModal>
  )
}

const Container = styled.div`
  display: flex;
  justify-contents: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  gap: 20px;
  padding: 10px;
  background-color: ${COLORS.black};
  color: ${COLORS.modalFont};
  font-family: ${FONTS.text};
  font-size: 25px;
  line-height: 22px;
  ${innerWideModalScreenStyles}
`

const Title = styled(shared.Title)`
  text-align: center;
  font-size: 45px;
  line-height: 41px;
  font-family: ${FONTS.text};
  @media only screen and (max-width: ${BREAKPOINTS.medium}) {
    font-size: 35px;
    line-height: 31px;
  }
`

const CancelButton = styled(ModalButton)`
  max-width: 400px;
  max-height: 65px;
`

const CloseButton = styled.div`
  text-transform: uppercase;
  font-size: 30px;
  cursor: pointer;
`
