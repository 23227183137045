import ReactModal from 'react-modal'
import styled from 'styled-components/macro'
import { useState } from 'react'

import {
  ApiErrorResponse,
  ReCaptchaCheckbox,
  isApiErrorResponse,
  useFetchWithReCaptcha,
  useUserId,
} from '../../api'
import {
  BREAKPOINTS,
  Button,
  ModalClose,
  ModalElement,
  ModalPortal,
  ModalProps,
  OverlayElement,
  Title,
} from '../../shared'

interface Props extends ModalProps {
  cancelOrderAPIUrl: string
  recaptchaV2SiteKey: string
  onError: (error: ApiErrorResponse) => void
}

export function CancelOrderModal({
  cancelOrderAPIUrl,
  recaptchaV2SiteKey,
  onError,
  isModalOpen,
  closeModal,
}: Props) {
  const userId = useUserId()
  const [displayReCaptchaV2Checkbox, setDisplayReCaptchaV2Checkbox] = useState(false)
  const [isCancellingOrder, setIsCancellingOrder] = useState(false)
  const [orderCancelled, setIsOrderCancelled] = useState(false)
  const fetchWithReCaptcha = useFetchWithReCaptcha('order')

  async function onReCaptchaV2CheckboxChange(token: string | null) {
    if (token) {
      setDisplayReCaptchaV2Checkbox(false)

      await cancelOrder()
    }
  }

  function handleClose() {
    resetData()
    closeModal()
  }

  function resetData() {
    setDisplayReCaptchaV2Checkbox(false)
    setIsCancellingOrder(false)
    setIsOrderCancelled(false)
  }

  async function cancelOrder() {
    try {
      setIsCancellingOrder(true)

      const response = await fetchWithReCaptcha(`${cancelOrderAPIUrl}/${userId}`, {})
      const json = await response.json()

      if (response.status === 200) {
        // artificial delay for cancel animation
        await new Promise((r) => setTimeout(r, 1000))
        setIsOrderCancelled(true)
        return
      } else if (json && isApiErrorResponse(json)) {
        if (json.name === 'ReCaptchaV3FailedError') {
          setDisplayReCaptchaV2Checkbox(true)
        } else {
          onError(json)
        }
      } else {
        throw new Error(json)
      }
    } catch (error) {
      console.error(error)
      onError({ name: 'UnknownError' })
    } finally {
      setIsCancellingOrder(false)
    }
  }

  let title = 'Order Already In Progress'
  let description =
    'Looks like you already have an order in progress. Please either cancel your current order or wait for it to finish before submitting another.'
  if (orderCancelled) {
    title = 'Order Cancelled'
    description =
      'Your order was successfully cancelled! You can now close this modal and submit a new order'
  } else if (isCancellingOrder) {
    title = 'Cancelling Order'
    description = 'Please wait while we cancel your order'
  }

  return (
    <ReactModal
      isOpen={isModalOpen}
      onRequestClose={handleClose}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
      ariaHideApp={false}
      className='modalElement'
      contentElement={(props, children) => (
        <ModalElementWrapper {...props}>{children}</ModalElementWrapper>
      )}
      overlayElement={(props, contentElement) => (
        <ModalPortal {...props}>
          <OverlayElement className='modelOverlay'>{contentElement}</OverlayElement>
        </ModalPortal>
      )}
    >
      <Container>
        <TitleWrapper>{title}</TitleWrapper>
        {description}
        {displayReCaptchaV2Checkbox && (
          <ReCaptchaCheckbox onChange={onReCaptchaV2CheckboxChange} siteKey={recaptchaV2SiteKey} />
        )}
        {!orderCancelled && (
          <Button onClick={cancelOrder} loading={isCancellingOrder}>
            Cancel existing order
          </Button>
        )}
        <ModalClose onClick={handleClose}>×</ModalClose>
      </Container>
    </ReactModal>
  )
}

const Container = styled.div`
  display: flex;
  justify-contents: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 10px;
  gap: 10px;
`

const TitleWrapper = styled(Title)`
  text-align: center;
  font-size: 45px;
  line-height: 41px;
  @media only screen and (max-width: ${BREAKPOINTS.medium}) {
    font-size: 35px;
    line-height: 31px;
  }
`

const ModalElementWrapper = styled(ModalElement)`
  max-width: 600px;
  padding: 15px;
`
