import { useEffect, useRef } from 'react'
import { Location } from 'react-router-dom'

export function usePreviousLocation(location: Location) {
  const prevLocRef = useRef(location)

  useEffect(() => {
    prevLocRef.current = location
  }, [location])

  return prevLocRef.current
}
