import ReactModal from 'react-modal'
import styled, { css } from 'styled-components/macro'

import { shared, api } from '@packages/ui'

import { WideModalElementWrapper, innerWideModalScreenStyles } from 'src/shared/components'
import { COLORS, FONTS } from 'src/shared/constants'

import { OrderErrorMessage } from './OrderErrorMessage'

interface Props extends shared.ModalProps {
  orderId?: number
  error: api.ApiErrorResponse
  onReorder?: () => void
  displayOverlay: boolean
}

export function OrderErrorModal({
  orderId,
  error,
  onReorder,
  displayOverlay,
  isModalOpen,
  closeModal,
}: Props) {
  return (
    <ReactModal
      isOpen={isModalOpen}
      onRequestClose={closeModal}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
      ariaHideApp={false}
      className='modalElement'
      contentElement={(props, children) => (
        <WideModalElementWrapper {...props}>{children}</WideModalElementWrapper>
      )}
      overlayElement={(props, contentElement) => (
        <ModalPortalWrapper {...props} displayOverlay={displayOverlay}>
          <shared.OverlayElement className='modelOverlay'>{contentElement}</shared.OverlayElement>
        </ModalPortalWrapper>
      )}
    >
      <OrderErrorMessageWrapper
        orderId={orderId}
        error={error}
        onReorder={onReorder}
        onOrderSomethingElse={closeModal}
      >
        <CloseButton onClick={closeModal}>exit</CloseButton>
      </OrderErrorMessageWrapper>
    </ReactModal>
  )
}

const OrderErrorMessageWrapper = styled(OrderErrorMessage)`
  background: ${COLORS.black};
  color: ${COLORS.modalFont};
  font-family: ${FONTS.text};
  font-size: 25px;
  line-height: 22px;
  justify-content: center;

  ${innerWideModalScreenStyles};
`

const ModalPortalWrapper = styled(shared.ModalPortal)<{ displayOverlay: boolean }>`
  ${(props) =>
    !props.displayOverlay &&
    css`
      &.ReactModal__Overlay--after-open {
        .modelOverlay {
          animation: none;
        }
      }
    `}
`

const CloseButton = styled.div`
  text-transform: uppercase;
  font-size: 30px;
  cursor: pointer;
`
