import styled from 'styled-components/macro'
import { Link } from 'react-router-dom'

import { BotMintForm } from 'src/bots'
import { Button } from 'src/shared/components'

import { NavbarOffsetContainer } from './components'
import { ROUTES } from './constants'

export function BotMintPage() {
  return (
    <NavbarOffsetContainer>
      <Container>
        <LinkWrapper to={ROUTES.home}>
          <Button>home</Button>
        </LinkWrapper>
        <BotMintForm />
      </Container>
    </NavbarOffsetContainer>
  )
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const LinkWrapper = styled(Link)`
  padding-top: 100px;
`
