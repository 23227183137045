export function getImageFileName(imageUrl: string) {
  const parts = imageUrl.split('/')
  return parts[parts.length - 1]
}

export function getImageHash(imageFileName: string) {
  return imageFileName.split('.')?.[0]
}

export function transformNFTImageUrl(imageUrl: string, baseUrl: string) {
  return getNFTImageUrl(getImageFileName(imageUrl), baseUrl)
}

export function getNFTImageUrl(imageFileName: string, baseUrl: string) {
  return `${baseUrl}${imageFileName}`
}
