import styled, { css } from 'styled-components'

import { BREAKPOINTS, COLORS } from '../constants'
import { hexToRGB } from '../utils'
import { StyledComponentProps } from '../interfaces'
import { Loading } from './Loading'

interface Props extends StyledComponentProps {
  onClick?: () => void
  loading?: boolean
  disabled?: boolean
  border?: boolean
  showTextOnLoading?: boolean
  type?: 'button' | 'submit' | 'reset'
}

export function Button({
  onClick,
  loading = false,
  disabled = false,
  border = false,
  showTextOnLoading = false,
  type = 'button',
  children,
  className,
}: Props) {
  return (
    <Container
      disabled={disabled}
      loading={loading}
      border={border}
      onClick={onClick}
      type={type}
      className={className}
    >
      {loading && <Loading />}
      {((loading && showTextOnLoading) || !loading) && children}
    </Container>
  )
}

export const Container = styled.button<{ loading: boolean; border: boolean }>`
  background-color: ${COLORS.mcPepeYellow};
  cursor: pointer;
  color: ${COLORS.white};
  padding: 14px 25px;
  border-radius: 50px;
  font-family: 'IntroBold', sans-serif;
  justify-content: center;
  align-items: center;
  display: flex;
  gap: 5px;

  font-size: 18px;
  font-weight: 400;
  transition: 0.5s;
  &:hover {
    opacity: 0.8;
  }
  @media only screen and (max-width: ${BREAKPOINTS.small}) {
    padding: 14px 15px;
  }
  ${(props) =>
    props.border &&
    css`
      background-color: ${COLORS.white};
      box-shadow: 0px 0px 0px 2px ${COLORS.mcPepeYellow} inset;
      color: ${COLORS.black};
    `}
  ${(props) =>
    props.loading &&
    css`
      pointer-events: none;
      cursor: disabled;
      background-color: ${hexToRGB(COLORS.mcPepeYellow, 0.8)};
    `}
  ${(props) =>
    props.disabled &&
    css`
      pointer-events: none;
      cursor: disabled;
      background-color: ${hexToRGB(COLORS.mcPepeYellow, 0.8)};
    `}
`
