import ReactModal from 'react-modal'
import styled, { css } from 'styled-components/macro'

import { ApiErrorResponse } from '../../api'
import { ModalClose, ModalElement, ModalPortal, ModalProps, OverlayElement } from '../../shared'
import { OrderErrorMessage } from './OrderErrorMessage'

interface Props extends ModalProps {
  orderId?: number
  error: ApiErrorResponse
  onReorder?: () => void
  displayOverlay: boolean
}

export function OrderErrorModal({
  orderId,
  error,
  onReorder,
  displayOverlay,
  isModalOpen,
  closeModal,
}: Props) {
  return (
    <ReactModal
      isOpen={isModalOpen}
      onRequestClose={closeModal}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
      ariaHideApp={false}
      className='modalElement'
      contentElement={(props, children) => (
        <ModalElementWrapper {...props}>{children}</ModalElementWrapper>
      )}
      overlayElement={(props, contentElement) => (
        <ModalPortalWrapper {...props} displayOverlay={displayOverlay}>
          <OverlayElement className='modelOverlay'>{contentElement}</OverlayElement>
        </ModalPortalWrapper>
      )}
    >
      <OrderErrorMessage
        orderId={orderId}
        error={error}
        onReorder={onReorder}
        onOrderSomethingElse={closeModal}
      />
      <ModalClose onClick={closeModal}>×</ModalClose>
    </ReactModal>
  )
}

const ModalPortalWrapper = styled(ModalPortal)<{ displayOverlay: boolean }>`
  ${(props) =>
    !props.displayOverlay &&
    css`
      &.ReactModal__Overlay--after-open {
        .modelOverlay {
          animation: none;
        }
      }
    `}
`

const ModalElementWrapper = styled(ModalElement)`
  max-width: 600px;
  padding: 15px;
`
