import styled from 'styled-components'

import { BREAKPOINTS, Z_INDEX } from '../constants'
import { StyledComponentProps } from '../interfaces'

export const MODAL_ANIMATION_DURATION = '1s'

export interface ModalProps extends StyledComponentProps {
  isModalOpen: boolean
  closeModal: () => void
}

export const ModalPortal = styled.div`
  position: fixed;
  display: table;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  transform: scale(0);
  z-index: ${Z_INDEX.modal};

  &.ReactModal__Overlay {
    background-color: transparent !important;
    background: none;
  }

  .modelOverlay {
    background-color: transparent;
    background: none;
  }

  &.ReactModal__Overlay--after-open {
    transform: scale(1);

    .modelOverlay {
      animation: fadeIn ${MODAL_ANIMATION_DURATION} cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
    }

    .modalElement {
      animation: scaleUp ${MODAL_ANIMATION_DURATION} cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
    }
  }

  &.ReactModal__Overlay--before-close {
    .modelOverlay {
      animation: fadeOut ${MODAL_ANIMATION_DURATION} cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
    }
  }

  @keyframes fadeIn {
    0% {
      background: rgba(0, 0, 0, 0);
    }
    100% {
      background: rgba(0, 0, 0, 0.7);
    }
  }

  @keyframes fadeOut {
    0% {
      background: rgba(0, 0, 0, 0.7);
    }
    100% {
      background: rgba(0, 0, 0, 0);
    }
  }

  @keyframes scaleUp {
    0% {
      transform: scale(0.8) translateY(1000px);
    }
    100% {
      transform: scale(1) translateY(0px);
    }
  }

  @keyframes scaleDown {
    0% {
      transform: scale(1) translateY(0px);
      opacity: 1;
    }
    100% {
      transform: scale(0.8) translateY(1000px);
      opacity: 0;
    }
  }
`

export const OverlayElement = styled.div`
  height: 100%;
  width: 100%;
  background: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
`

export const ModalContainer = styled.div`
  width: 100%;
`

export const ModalElement = styled.div`
  width: 100%;
  max-width: 400px;
  max-height: 90vh;
  position: fixed;
  transform: translate(-50%, -50%);

  background-color: white;
  overflow: auto;
  border-radius: 4px;
  outline: none;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12);
  padding: 30px;

  @media (max-width: ${BREAKPOINTS.small}) {
    width: 95%;
  }
`

export const ModalClose = styled.button`
  font-size: 30px;
  font-weight: 700;
  line-height: 40px;
  position: absolute;
  top: 0;
  right: 5px;
  border: none;
  background: none;
  cursor: pointer;
`
