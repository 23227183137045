import styled, { css } from 'styled-components'

import { ReactComponent as DownArrow } from '../assets/down-arrow.svg'

export const Arrow = styled(DownArrow)<{ facingDown: boolean }>`
  transform: scaleY(-1);
  ${(props) =>
    props.facingDown &&
    css`
      transform: none;
    `}
`
