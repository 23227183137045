import { getBoolEnv, getEnv, getIntEnv } from '@packages/ui/src/shared/utils'

export const INFURA_API_KEY = getEnv('REACT_APP_INFURA_API_KEY')
export const DEPLOYED_CHAIN_ID = getIntEnv('REACT_APP_DEPLOYED_CHAIN_ID', 1)
export const WALLET_CONNECT_PROJECT_ID = getEnv('REACT_APP_WALLET_CONNECT_PROJECT_ID')

export const ERC721_MINTER_CONTRACT_ADDRESS = getEnv('REACT_APP_ERC721_MINTER_CONTRACT_ADDRESS')
export const ERC721_CONTRACT_ADDRESS = getEnv('REACT_APP_ERC721_CONTRACT_ADDRESS')

export const DOGELON_TOKEN_CONTRACT_ADDRESS = getEnv('REACT_APP_DOGELON_TOKEN_CONTRACT_ADDRESS')

export const MINT_LAUNCH_UNIX_MS = getIntEnv('REACT_APP_MINT_LAUNCH_UNIX_MS', 1689424580000)
export const MINT_STARTED = getBoolEnv('REACT_APP_MINT_STARTED', false)

export const SHOW_MAINTENANCE_NOTICE = getBoolEnv('REACT_APP_SHOW_MAINTENANCE_NOTICE', false)

export const API_URL = getEnv('REACT_APP_API_URL')
export const NFT_BASE_URL = getEnv('REACT_APP_NFT_BASE_URL')

export const RECAPTCHA_V3_SITE_KEY = getEnv('REACT_APP_RECAPTCHA_V3_SITE_KEY')
export const RECAPTCHA_V2_SITE_KEY = getEnv('REACT_APP_RECAPTCHA_V2_SITE_KEY')

export const LINKS = {
  website: 'https://dogelonmars.com',
  discord: 'https://discord.gg/dogelonmars',
  telegram: 'https://t.me/dogelonmars',
  uniswap:
    'https://app.uniswap.org/#/swap?inputCurrency=eth&outputCurrency=0x761d38e5ddf6ccf6cf7c55759d5210750b5d60f3',
  twitter: 'https://twitter.com/dogelonmars',
  twitterUsername: '@DogelonMars',
  opensea: 'https://opensea.io/collection/dogelonainft',
  coinmarketcap: 'https://coinmarketcap.com/currencies/dogelon/',
  coingecko: 'https://www.coingecko.com/en/coins/dogelon-mars',
  embeddedImageUrl: 'https://i.dogelonmars.com/',
}
