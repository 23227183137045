import { useEffect, useState } from 'react'

import { shared } from '@packages/ui'

import { API_URL } from 'src/settings'
import { API_ENDPOINT } from 'src/apiEndpoints'

interface DogelonContractState {
  mintingEnabled: boolean
  tokenId: number
  maxSupply: number
  imageUpdatesEnabled: boolean
  startTime: number
}

const initialState: DogelonContractState = {
  mintingEnabled: true,
  tokenId: 1,
  maxSupply: 9000,
  imageUpdatesEnabled: false,
  startTime: 0,
}

export function useERC721ContractState() {
  const [state, setState] = useState<DogelonContractState>(initialState)

  useEffect(() => {
    getContractState({ setState })
  }, [])

  shared.useInterval(() => getContractState({ setState }), 30000) // 30 seconds

  return state
}

async function getContractState({
  setState,
}: {
  setState: (contractState: DogelonContractState) => void
}) {
  try {
    const response = await fetch(API_URL + API_ENDPOINT.GET.contractState)

    if (response.ok) {
      const json = await response.json()
      setState(json)
    }
  } catch (error) {
    console.error('Error getting contract state')
  }
}
