import styled, { css } from 'styled-components'

import { BREAKPOINTS, COLORS, Z_INDEX } from '../constants'
import { StyledComponentProps } from '../interfaces'
import { Button as _Button } from './Button'

interface Props extends StyledComponentProps {
  onClick: () => void
  disabled: boolean
  canCancel: boolean
  cancelText?: string
  onCancel: () => void
}

export function ButtonWithCancel({
  disabled = false,
  onClick,
  canCancel = true,
  cancelText,
  onCancel,
  children,
  className,
}: Props) {
  return (
    <Container className={className}>
      {canCancel && <CancelButton onClick={onCancel}>{cancelText ?? 'Cancel'}</CancelButton>}
      <ActionButton loading={canCancel} showTextOnLoading onClick={onClick} disabled={disabled}>
        {children}
      </ActionButton>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex: 1;
`

const Button = styled(_Button)`
  max-width: 200px;
  flex: 1;
  font-size: 16px;
  a {
    font-size: 16px;
  }
  @media only screen and (max-width: ${BREAKPOINTS.medium}) {
    font-size: 14px;
    padding: 10px 8px;

    div {
      width: 18px;
      height: 18px;
    }
    a {
      font-size: 14px;
    }
  }
`

const ActionButton = styled(Button)<{ loading?: boolean }>`
  flex: 1;
  ${(props) =>
    props.loading &&
    css`
      margin-left: 30%;
      background-color: ${COLORS.mcPepeYellow};
      z-index: ${Z_INDEX.content};
      &:hover {
        opacity: 1;
      }
      @media only screen and (max-width: ${BREAKPOINTS.medium}) {
        height: 41px;
        margin-left: 42%;
        margin-right: 3%;
      }
    `}
`

const CancelButton = styled(Button)`
  z-index: 0;
  position: absolute;
  text-align: left;
  justify-content: left;
  padding-left: 5%;
  padding-right: 25%;
  background-color: ${COLORS.mcPepeRed};
  margin-left: auto;
  margin-right: auto;
  left: -20%;
  right: 0;
  animation: unfurl 500ms linear;

  @keyframes unfurl {
    0% {
      left: 22%;
    }
    100% {
      left: -20%;
    }
  }

  @media only screen and (max-width: ${BREAKPOINTS.medium}) {
    padding-left: 10%;
    padding-right: 30%;
  }
`
