type ROUTING_CONTROLLER_SUPPORTED_ERRORS =
  | 'BadRequestError'
  | 'InternalServerError'
  | 'NotFoundError'
  | 'UnauthorizedError'
type API_ERRORS =
  | 'ReCaptchaV2FailedError'
  | 'ReCaptchaV3FailedError'
  | 'OrderPollingTimeoutError'
  | 'OrderPollingMaxCountError'
  | 'ModelServerError'
  | 'RateLimitError'
  | 'OrderExpiredError'
  | 'OrderInProgressError'
  | 'OrderCancelledError'

export interface ApiErrorResponse {
  name:
    | ROUTING_CONTROLLER_SUPPORTED_ERRORS
    | API_ERRORS
    | 'NetworkError'
    | 'UnknownError'
    | 'CustomError'
  message?: string
  title?: string
}

export function isApiErrorResponse(ApiErrorResponse: any): ApiErrorResponse is ApiErrorResponse {
  return 'name' in ApiErrorResponse
}
