import styled, { css } from 'styled-components'

import { shared } from '@packages/ui'

import { COLORS } from '../constants'

const { BREAKPOINTS } = shared

export const innerWideModalScreenStyles = css`
  border-top-left-radius: 30px 50px;
  border-top-right-radius: 30px 50px;
  border-bottom-right-radius: 30px 50px;
  border-bottom-left-radius: 30px 50px;
  @media only screen and (max-width: ${BREAKPOINTS.medium}) {
    border-top-left-radius: 15px 35px;
    border-top-right-radius: 15px 35px;
    border-bottom-right-radius: 15px 35px;
    border-bottom-left-radius: 15px 35px;
  }
`

export const outerWideModalScreenStyles = css`
  border-top-left-radius: 20px 50px;
  border-top-right-radius: 20px 50px;
  border-bottom-right-radius: 20px 50px;
  border-bottom-left-radius: 20px 50px;
`

export const ModalElementWrapper = styled(shared.ModalElement)`
  max-width: 600px;
  border-radius: 3px;
  background-color: #1e1815;
  box-shadow: inset 4px 5px 12px 1px black;
  border-width: 15px;
  border-style: solid;
  border-color: #1e1815 #2d2825 #1e1815 #2d2825;
  ${outerWideModalScreenStyles}
  padding: 15px;
  > div {
    width: 100%;
    height: 100%;
  }
`

export const WideModalElementWrapper = styled(ModalElementWrapper)`
  max-width: 600px;
  max-height: 400px;
  height: 80vh;
`

export const ModalClose = styled(shared.ModalClose)`
  color: ${COLORS.buttons.background};
  top: 12px;
  right: 23px;
`
