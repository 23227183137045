import { useNavigate, useSearchParams } from 'react-router-dom'
import styled from 'styled-components/macro'
import { useEffect, useRef, useState } from 'react'
import { useWeb3Modal } from '@web3modal/react'

import { api, nfts } from '@packages/ui'

import { OrderErrorModal, OrderMintingModal } from 'src/orders'
import { ORDER_SEARCH_PARAMS, ROUTES } from 'src/pages/constants'
import { NFT_BASE_URL } from 'src/settings'

export function BotMintForm() {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const imageFileName = searchParams.get(ORDER_SEARCH_PARAMS.imageFileName)
  const orderIdStr = searchParams.get(ORDER_SEARCH_PARAMS.orderId)
  const imageUrl = imageFileName ? nfts.getNFTImageUrl(imageFileName, NFT_BASE_URL) : ''
  const imageHash = imageFileName ? nfts.getImageHash(imageFileName) : undefined
  const [orderId, setOrderId] = useState<number>()

  const { isOpen: isWeb3ModalOpen } = useWeb3Modal()
  const errorRef = useRef<api.ApiErrorResponse>()
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false)
  const [isMintModalOpen, setIsMintModalOpen] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      if (!errorRef.current) {
        setIsMintModalOpen(true)
      }
    }, 1000)
  }, [])

  useEffect(() => {
    if (!imageFileName && !imageHash) {
      errorRef.current = {
        name: 'CustomError',
        title: 'Invalid Transmission',
        message: 'Missing Image File Name',
      }
      toggleModalsOnError()
    }
  }, [imageFileName, imageHash])

  useEffect(() => {
    if (!orderIdStr) {
      errorRef.current = {
        name: 'CustomError',
        title: 'Invalid Transmission',
        message: 'Missing Transmission ID',
      }
      toggleModalsOnError()
    } else if (isNaN(parseInt(orderIdStr))) {
      errorRef.current = {
        name: 'CustomError',
        title: 'Invalid Transmission',
        message: 'Invalid Transmission ID',
      }
      toggleModalsOnError()
    } else {
      setOrderId(parseInt(orderIdStr))
    }
  }, [orderIdStr])

  function isInvalidImage() {
    errorRef.current = {
      name: 'CustomError',
      title: 'Invalid Transmission',
      message: 'Invalid Image File Name',
    }
    toggleModalsOnError()
  }

  function toggleModalsOnError() {
    setIsErrorModalOpen(true)
    setIsMintModalOpen(false)
  }

  function closeMintModal() {
    setIsMintModalOpen(false)
  }

  function closeErrorModal() {
    setIsErrorModalOpen(false)
  }

  return (
    <>
      <Image src={imageUrl} onError={() => isInvalidImage()} />
      <Container>
        {imageUrl && imageHash && orderId && (
          <OrderMintingModal
            isModalOpen={isMintModalOpen && !isWeb3ModalOpen}
            closeModal={closeMintModal}
            order={{
              id: orderId,
              uncompressedImageUrl: imageUrl,
              finalImageUrl: imageUrl,
              finalImageHash: imageHash,
              completedAtMs: Date.now(),
            }}
            displayOverlay
            onReorder={async () => navigate({ pathname: ROUTES.generate })}
          />
        )}
        {errorRef.current && (
          <OrderErrorModal
            isModalOpen={isErrorModalOpen}
            closeModal={closeErrorModal}
            displayOverlay
            orderId={orderId}
            error={errorRef.current}
          />
        )}
      </Container>
    </>
  )
}

const Container = styled.div`
  margin-top: -2px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 50px;
  min-height: 400px;
`

const Image = styled.img`
  display: none;
`
