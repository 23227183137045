import styled, { css } from 'styled-components'

import { NFT } from '@packages/interfaces'

import { ReactComponent as ExternalLink } from '../../shared/assets/external-link.svg'
import { A, COLORS, GracefullyLoadedImg, StyledComponentProps } from '../../shared'

interface Props extends StyledComponentProps {
  nft: NFT

  width: number
  onClick: () => void
  selected?: boolean
  useAnimationUrl?: boolean
  useThumbnail?: boolean
  externalUrl?: string
}

export function NFTThumbnail({
  nft,
  width,
  onClick,
  selected = false,
  useAnimationUrl = false,
  useThumbnail = false,
  externalUrl,
  className,
}: Props) {
  let imageUrl = nft.imageUrl
  if (useAnimationUrl && nft.animationUrl) {
    imageUrl = nft.animationUrl
  } else if (useThumbnail || width < 150) {
    imageUrl = nft.imageThumbnailUrl
  }
  return (
    <Container selected={selected} onClick={onClick} className={className}>
      <Image src={imageUrl} width={width} />
      <Title width={width}>
        {nft.title}
        {externalUrl && (
          <A href={externalUrl} target='_blank' rel='noreferrer'>
            <ExternalLink width='25' height='25' />
          </A>
        )}
      </Title>
    </Container>
  )
}

const Container = styled.div<{ selected: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 5px;
  padding: 5px 15px;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  border: 3px solid rgba(0, 0, 0, 0);
  cursor: pointer;
  ${(props) =>
    props.selected &&
    css`
      border: 3px solid ${COLORS.mcPepeYellow};
    `}
`

const Image = styled(GracefullyLoadedImg)<{ width: number }>`
  width: ${(props) => props.width}px;
`

const Title = styled.div<{ width: number }>`
  font-size: ${(props) => props.width / 9}px;
  font-weight: 700;
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
`
