import { goerli, mainnet } from 'wagmi/chains'

export function getOpenSeaNFTUrl(chainId: number, tokenId: number, contractAddress: string) {
  switch (chainId) {
    case goerli.id:
      return `https://testnets.opensea.io/assets/goerli/${contractAddress}/${tokenId}`
    case mainnet.id:
    default:
      return `https://opensea.io/assets/ethereum/${contractAddress}/${tokenId}`
  }
}
