import styled from 'styled-components'

import { ApiErrorResponse } from '../../api'
import { ReactComponent as SadPepe } from '../../shared/assets/pepe-sad.svg'
import { BREAKPOINTS, Button, Title as _Title } from '../../shared'
import NetworkErrorImg from '../assets/errors/computer-error.svg'
import OrderExpiredImg from '../assets/errors/order-expired.svg'
import OrderMissingImg from '../assets/errors/order-missing.svg'
import OrderCancelledImg from '../assets/errors/order-cancelled.svg'
import RecaptchaFailedImg from '../assets/errors/recaptcha-failed.svg'
import TooManyRequestsImg from '../assets/errors/too-many-requests.svg'

type Props = {
  orderId?: number
  error: ApiErrorResponse
  onReorder?: () => void
  onOrderSomethingElse?: () => void
  onTryAgain?: () => void
  getCustomDisplay?: (error: ApiErrorResponse) => {
    image: React.ReactNode
    title: string
    description: string
    button?: React.ReactNode
  }
}

export function OrderErrorMessage({
  orderId,
  error,
  onReorder,
  onOrderSomethingElse,
  onTryAgain,
  getCustomDisplay,
}: Props) {
  let image: React.ReactNode
  let title: string
  let description: string
  let button: React.ReactNode
  if (getCustomDisplay) {
    const custom = getCustomDisplay(error)
    image = custom.image
    title = custom.title
    description = custom.description
    button = custom.button
  } else {
    image = <SadPepeImg />
    title = 'Bad Request Man'
    description = 'Something went wrong while processing your request.'
    button

    switch (error.name) {
      case 'CustomError':
        title = error?.title ? error.title : title
        description = error?.message ? error.message : description
        break
      case 'OrderExpiredError':
        image = <OrderExpiredImgWrapper src={OrderExpiredImg} />
        title = 'Order Expired'
        description =
          'Sorry, your order has gone bad and you can no longer mint it. Please submit a new order.'
        break
      case 'OrderCancelledError':
        image = <Img src={OrderCancelledImg} />
        title = 'Order Cancelled'
        description = 'Looks like this order has been cancelled. Please submit a new order.'
        break
      case 'NotFoundError':
        image = <Img src={OrderMissingImg} />
        title = 'Uh-oh'
        description =
          // eslint-disable-next-line @typescript-eslint/quotes
          "I'm sorry for the inconvenience, your order was accidentally discarded. Let's redo it for you."
        button = onOrderSomethingElse ? (
          <Button onClick={onOrderSomethingElse} border>
            Order something else
          </Button>
        ) : (
          <></>
        )
        break
      case 'ModelServerError':
        image = <Img src={OrderMissingImg} />
        title = 'Uh-oh'
        description =
          // eslint-disable-next-line @typescript-eslint/quotes
          "I'm sorry for the inconvenience, your order was accidentally discarded. Let's redo it for you."
        button = onReorder ? (
          <Button onClick={onReorder} border>
            Re-order
          </Button>
        ) : (
          <></>
        )
        break
      case 'ReCaptchaV2FailedError':
      case 'ReCaptchaV3FailedError':
        image = <Img src={RecaptchaFailedImg} />
        title = 'Captcha Fail!'
        description = 'Better luck next time "human".'
        break
      case 'RateLimitError':
        image = <Img src={TooManyRequestsImg} />
        title = 'Too many requests'
        description = 'I guess you were just too fast for the system to handle.'
        break
      case 'NetworkError':
        image = <Img src={NetworkErrorImg} />
        title = 'Connection Issue'
        description =
          // eslint-disable-next-line @typescript-eslint/quotes
          "I'm sorry, we're having trouble processing your request at the moment due to a connection issue. Can you try again later?"
        break
    }
  }

  const canTryAgain = error.name !== 'OrderExpiredError'

  return (
    <Container>
      {image}
      <Title>{title}</Title>
      {orderId && <OrderId>Order #{orderId}</OrderId>}
      {description && <Description>{description}</Description>}
      <ButtonsContainer>
        {button} {onTryAgain && canTryAgain && <Button onClick={onTryAgain}>Try Again?</Button>}
      </ButtonsContainer>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  justify-contents: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 10px;
  gap: 15px;
`

const Title = styled(_Title)`
  text-align: center;
  font-size: 45px;
  line-height: 41px;
  @media only screen and (max-width: ${BREAKPOINTS.medium}) {
    font-size: 35px;
    line-height: 31px;
  }
`

const OrderId = styled.div`
  font-size: 22px;
  text-align: center;
  @media only screen and (max-width: ${BREAKPOINTS.medium}) {
    font-size: 18px;
    line-height: 22px;
  }
`

const Description = styled.div`
  font-size: 18px;
  text-align: center;
  @media only screen and (max-width: ${BREAKPOINTS.medium}) {
    font-size: 16px;
    line-height: 20px;
  }
`

const ButtonsContainer = styled.div`
  gap: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 400px;
  @media only screen and (max-width: ${BREAKPOINTS.medium}) {
    gap: 10px;
  }
`

const SadPepeImg = styled(SadPepe)`
  height: 200px;
  width: 200px;
  @media only screen and (max-width: ${BREAKPOINTS.medium}) {
    height: 120px;
    width: 120px;
  }
`

const Img = styled.img`
  height: 175px;
  width: 175px;
  @media only screen and (max-width: ${BREAKPOINTS.medium}) {
    height: 140px;
    width: 140px;
  }
`

const OrderExpiredImgWrapper = styled.img`
  height: 290px;
  width: 290px;
  margin: -50px;
  @media only screen and (max-width: ${BREAKPOINTS.medium}) {
    height: 240px;
    width: 240px;
    margin: -40px;
  }
`
