import styled from 'styled-components'

import { ReactComponent as TwitterLogo } from '../assets/social-logos/twitter.svg'
import { A } from './A'
import { StyledComponentProps } from '../interfaces'

const TWITTER_BLUE = '#24abe1'
// https://developer.twitter.com/en/docs/twitter-for-websites/tweet-button/overview
const TWITTER_SHARE_BASE_URL = 'https://twitter.com/intent/tweet'

interface Props extends StyledComponentProps {
  tweetText: string
  imageUrl: string
}

export function TwitterShareLink({ tweetText, imageUrl, className }: Props) {
  const twitterUrl = `${TWITTER_SHARE_BASE_URL}?text=${tweetText}&url=${imageUrl}`
  return (
    <Link
      className={`twitter-share-button ${className}`}
      href={twitterUrl}
      target='_blank'
      rel='noreferrer'
    >
      <TwitterLogo fill={TWITTER_BLUE} /> Share
    </Link>
  )
}

const Link = styled(A)`
  font-family: 'IntroBold';
  font-weight: 500;
  color: ${TWITTER_BLUE};
  display: flex;
  align-items: center;
  gap: 3px;
`
