import styled from 'styled-components'
import { useAutoAnimate } from '@formkit/auto-animate/react'
import { useState } from 'react'

import { Arrow } from './Arrow'

interface Props {
  linkText: string
  children?: React.ReactNode
  useAnimation?: boolean
}

export function ExpandArea({ linkText, useAnimation, children }: Props) {
  const [parent] = useAutoAnimate()
  const [expanded, setExpanded] = useState(false)

  function onExpand() {
    setExpanded(!expanded)
  }

  const containerProps = {
    ...(useAnimation && { ref: parent }),
  }

  return (
    <Container {...containerProps}>
      <ExpandLink onClick={onExpand}>
        <ExpandLinkText>{expanded ? `Hide ${linkText}` : `Expand ${linkText}`}</ExpandLinkText>
        <Arrow facingDown={!expanded} />
      </ExpandLink>
      {expanded && <Content>{children}</Content>}
    </Container>
  )
}

const Container = styled.div<{ ref?: any }>`
  width: 100%;
`

const ExpandLink = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const ExpandLinkText = styled.span`
  font-weight: 700;
`

const Content = styled.div``
