import { useEffect } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { shared } from '@packages/ui'

export const USER_ID_LOCAL_STORAGE_KEY = 'USER_ID'

export function useUserId(): string {
  const [userId, setUserId] = shared.useLocalStorage(USER_ID_LOCAL_STORAGE_KEY)

  useEffect(() => {
    if (!userId) {
      setUserId(uuidv4())
    }
  }, [userId])

  return userId
}
