'use client'
import styled, { css } from 'styled-components'
import Chance from 'chance'
import { useRef } from 'react'
import { useAutoAnimate } from '@formkit/auto-animate/react'

import { ReCaptchaCheckbox, useInstanceLoader } from '../../api'
import { BREAKPOINTS, COLORS, Z_INDEX } from '../constants'
import { generateReactKey } from '../utils'
import { Button } from './Button'
import { StyledComponentProps } from '../interfaces'

interface Props extends StyledComponentProps {
  userPrompt: string
  setUserPrompt: (prompt: string) => void
  userPromptInputRef: React.RefObject<HTMLInputElement>
  setSanitizedUserPrompt: (prompt: string) => void
  displayRequiredPromptError: boolean
  setDisplayRequiredPromptError: (display: boolean) => void
  displayReCaptchaV2Checkbox: boolean
  onReCaptchaV2CheckboxChange: (token: string | null) => void
  reCaptchaV2SiteKey: string
  onSubmit: (event: React.SyntheticEvent) => void
  isSubmitting: boolean
  placeholder?: string
  examplePrompts?: string[]
  randomPrompts?: { prompts: string[]; styles: string[] }
  descriptions?: Descriptions
}

interface Descriptions {
  noun?: string
  randomPrompt?: string
  submitButtonText?: string
  promptRequiredError: string
}

const DEFAULT_DESCRIPTIONS: Descriptions = {
  noun: 'Pepe',
  // eslint-disable-next-line @typescript-eslint/quotes
  randomPrompt: "Can't decide? Order a random combo meal",
  submitButtonText: 'Order',
  promptRequiredError: 'Please enter a prompt',
}

const PROMPT_REQUIREMENTS = {
  NON_ALPHANUMERIC_WHITESPACE_REGEX: /[^0-9A-Za-zÀ-ÖØ-öø-ÿ-',\\."':;!\\?\-&\s]/gi,
  CHARACTER_LIMIT: 1000,
}
const ORDER_FORM_HTML_ID = 'request-form'
const chance = new Chance()

export function UserPromptInput({
  userPrompt,
  setUserPrompt,
  userPromptInputRef,
  setSanitizedUserPrompt,
  displayRequiredPromptError,
  setDisplayRequiredPromptError,
  displayReCaptchaV2Checkbox,
  onReCaptchaV2CheckboxChange,
  reCaptchaV2SiteKey,
  onSubmit,
  isSubmitting,
  placeholder,
  examplePrompts = [],
  randomPrompts,
  descriptions = DEFAULT_DESCRIPTIONS,
  className,
}: Props) {
  const grecaptcha = useInstanceLoader(reCaptchaV2SiteKey)
  const userPromptTextAreaRef = useRef<HTMLInputElement>(null)
  const [parent] = useAutoAnimate()

  function focusOnInputs() {
    userPromptInputRef.current?.focus()
  }

  function updateUserPrompt(prompt: string) {
    const trimmedPrompt = prompt.substring(0, PROMPT_REQUIREMENTS.CHARACTER_LIMIT)
    setUserPrompt(trimmedPrompt)
    const sanitizedPrompt = trimmedPrompt.replace(
      PROMPT_REQUIREMENTS.NON_ALPHANUMERIC_WHITESPACE_REGEX,
      ''
    )
    setSanitizedUserPrompt(sanitizedPrompt)
    if (sanitizedPrompt.length > 0) {
      setDisplayRequiredPromptError(false)
    }
  }

  function autoFillPrompt() {
    if (randomPrompts) {
      updateUserPrompt(
        `${chance.pickone(randomPrompts.prompts)}, ${chance.pickone(randomPrompts.styles)}`
      )
      focusOnInputs()
    }
  }

  function onUserPromptChange(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    const value = e.target.value
    updateUserPrompt(value)
  }

  return (
    <>
      <OrderFormContainer id={ORDER_FORM_HTML_ID} className={className}>
        <OrderForm onSubmit={onSubmit} executeAnimation={displayRequiredPromptError}>
          <InputContainer>
            {descriptions.noun && (
              <Noun executeAnimation={displayRequiredPromptError}>{descriptions.noun}</Noun>
            )}
            <ExamplePrompt executeAnimation={displayRequiredPromptError}>
              {!userPrompt && (
                <Placeholders examplePrompts={examplePrompts}>
                  {examplePrompts.map((prompt) => (
                    <Placeholder key={generateReactKey(prompt)}>{prompt}</Placeholder>
                  ))}
                </Placeholders>
              )}
            </ExamplePrompt>
            <Input
              autoFocus
              type='text'
              value={userPrompt}
              onChange={onUserPromptChange}
              ref={userPromptInputRef}
              placeholder={placeholder}
            />
            <TextArea
              value={userPrompt}
              onChange={onUserPromptChange}
              ref={userPromptTextAreaRef}
              placeholder={placeholder}
            />
          </InputContainer>
          {descriptions.submitButtonText && (
            <SubmitButton type='submit' loading={isSubmitting}>
              {descriptions.submitButtonText}
            </SubmitButton>
          )}
        </OrderForm>
      </OrderFormContainer>
      {(displayReCaptchaV2Checkbox || displayRequiredPromptError) && (
        <ErrorContainer ref={parent}>
          {displayReCaptchaV2Checkbox && (
            <ReCaptchaCheckbox
              onChange={onReCaptchaV2CheckboxChange}
              siteKey={reCaptchaV2SiteKey}
              grecaptcha={grecaptcha}
            />
          )}
          {displayRequiredPromptError && (
            <RequiredPromptError>{descriptions.promptRequiredError}</RequiredPromptError>
          )}
        </ErrorContainer>
      )}
      {descriptions.randomPrompt && (
        <>
          <RandomPromptContainer onClick={autoFillPrompt}>
            {descriptions.randomPrompt}
          </RandomPromptContainer>
          <RandomPromptButton onClick={autoFillPrompt}>
            {descriptions.randomPrompt}
          </RandomPromptButton>
        </>
      )}
    </>
  )
}

const OrderFormContainer = styled.div`
  display: flex;
  margin: 0 auto;
  max-width: ${BREAKPOINTS.large};
  width: 100%;
`

const OrderForm = styled.form<{ executeAnimation: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  padding-left: 20px;
  color: ${COLORS.black};

  border-radius: 50px;
  background-color: ${COLORS.white};

  height: 72px;
  @media only screen and (max-width: ${BREAKPOINTS.medium}) {
    height: 160px;
    border-radius: 13px;
    flex-direction: column;
    padding-left: 0px;
  }

  ${(props) =>
    props.executeAnimation &&
    css`
      animation: shake 300ms;

      @keyframes shake {
        25% {
          transform: translateX(4px);
        }
        50% {
          transform: translateX(-4px);
        }
        75% {
          transform: translateX(4px);
        }
      }
    `}
`

const InputContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  @media only screen and (max-width: ${BREAKPOINTS.medium}) {
    display: block;
  }
`

const Noun = styled.div<{ executeAnimation: boolean }>`
  z-index: ${Z_INDEX.content};
  position: relative;
  left: 5px;
  font-size: 18px;
  background-color: white;
  border-radius: 3px;
  @media only screen and (max-width: ${BREAKPOINTS.medium}) {
    position: absolute;
    top: 34px;
    left: 44px;

    ${(props) =>
      props.executeAnimation &&
      css`
        animation: movePepeNoun 300ms;

        @keyframes movePepeNoun {
          0%,
          100% {
            top: 14px;
            left: 24px;
          }
        }
      `}
  }
`

const EXAMPLE_PROMPT_LINE_HEIGHT = 25

const ExamplePrompt = styled.div<{ executeAnimation: boolean }>`
  position: absolute;
  overflow: hidden;
  padding-top: 1px;
  height: ${EXAMPLE_PROMPT_LINE_HEIGHT}px;
  user-select: none;
  max-width: ${BREAKPOINTS.large};
  white-space: nowrap;

  @media only screen and (max-width: ${BREAKPOINTS.medium}) {
    top: 36px;
    height: ${EXAMPLE_PROMPT_LINE_HEIGHT * 2}px;
    white-space: normal;
    padding: 0px 23px;
    ${(props) =>
      props.executeAnimation &&
      css`
        animation: moveExamplePrompt 300ms;

        @keyframes moveExamplePrompt {
          0% {
            top: 16px;
          }
          100% {
            top: 16px;
          }
        }
      `}
  }
  @media only screen and (max-width: ${BREAKPOINTS.small}) {
    height: ${EXAMPLE_PROMPT_LINE_HEIGHT * 3}px;
  }
`

function createExamplePromptCSS(examplePrompts: string[]) {
  const animationLength = examplePrompts.length * 2 - 2
  // adjust 'Y' and `animation-duration` if length changes
  const Y = 2
  const X = (100 - animationLength * Y) / animationLength

  let styles = `
    0%,
    ${X}%,
    100% {
      transform: translate3d(0, 0, 0);
    }
  `

  let current = X
  let percent = 0
  const percentInc = 100 / examplePrompts.length
  let decreasing = true

  for (let i = 0; i < animationLength; i += 1) {
    if (decreasing) {
      percent -= percentInc
    } else {
      percent += percentInc
    }

    if (percent <= -(100 - percentInc)) {
      decreasing = false
    }

    styles += `
      ${(current += Y)}%,
      ${(current += X)}% {
        transform: translate3d(0, ${percent}%, 0);
      }
     `
  }

  return css`
    ${styles}
  `
}

const Placeholders = styled.ul<{ examplePrompts: string[] }>`
  padding-inline-start: 0px;
  text-indent: 53px;
  list-style: none;
  text-align: left;
  color: rgb(117, 117, 117);
  // https://codepen.io/yoannhel/pen/DMzjog
  @keyframes change {
    ${(props) => createExamplePromptCSS(props.examplePrompts)};
  }
  animation-name: change;
  animation-duration: 24s;
  animation-iteration-count: infinite;

  @media only screen and (max-width: ${BREAKPOINTS.medium}) {
    text-indent: 47px;
  }
`

const Placeholder = styled.li`
  font-size: 16px;
  height: ${EXAMPLE_PROMPT_LINE_HEIGHT}px;
  @media only screen and (max-width: ${BREAKPOINTS.medium}) {
    height: ${EXAMPLE_PROMPT_LINE_HEIGHT * 2}px;
  }
  @media only screen and (max-width: ${BREAKPOINTS.small}) {
    height: ${EXAMPLE_PROMPT_LINE_HEIGHT * 3}px;
  }
`

const Input = styled.input`
  z-index: ${Z_INDEX.content};
  position: relative;
  padding: 14px 25px;
  padding-left: 10px;
  border-radius: 50px;
  font-size: 16px;
  border: none;
  height: 100%;
  width: 100%;
  background-color: transparent;
  &:focus {
    outline: none;
  }
  @media only screen and (max-width: ${BREAKPOINTS.medium}) {
    display: none;
  }
`

const TextArea = styled.textarea<{ ref: any }>`
  z-index: ${Z_INDEX.content};
  position: relative;
  display: none;
  padding: 14px 25px;
  border-radius: 13px;
  font-size: 16px;
  border: none;
  height: 100%;
  width: 100%;
  background-color: transparent;
  text-indent: 46px;
  resize: none;
  &:focus {
    outline: none;
  }
  @media only screen and (max-width: ${BREAKPOINTS.medium}) {
    padding: 16px 25px;
    display: inline-block;
  }
`

const SubmitButton = styled(Button)`
  z-index: ${Z_INDEX.content};
  position: relative;
  height: 50px;
  width: 150px;
  padding: 10px;
  margin-right: 10px;
  ${(props) =>
    props.disabled &&
    css`
      background-color: #f4c661;
    `}

  @media only screen and (max-width: ${BREAKPOINTS.medium}) {
    margin: 10px 0px;
  }
`

const ErrorContainer = styled.div<{ ref: any }>``

const RequiredPromptError = styled.div`
  font-weight: 700;
  color: #a70707;
  padding-bottom: 15px;
`

const RandomPromptButton = styled(Button)`
  display: none;
  margin-top: -15px;
  background-color: ${COLORS.mcPepeRed};
  transition: 250ms ease-in;
  font-size: 14px;
  &:hover {
    opacity: 1;
  }
  @media only screen and (max-width: ${BREAKPOINTS.medium}) {
    display: block;
  }
`

const RandomPromptContainer = styled.span`
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  transition: 250ms ease-in;
  &:hover {
    opacity: 1;
  }
  @media only screen and (max-width: ${BREAKPOINTS.medium}) {
    display: none;
  }
`
