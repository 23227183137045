import { useEffect, useState } from 'react'
import { useAccount } from 'wagmi'

import { shared } from '@packages/ui'
import { NFT } from '@packages/interfaces'

import { usePageVisibility } from '../../shared'

export function useNFTs(apiUrl: string, contractAddress: string, refreshMetadata = false) {
  const { address } = useAccount()
  const isPageVisible = usePageVisibility()
  const [loading, setLoading] = useState(true)
  const [nfts, setNFTs] = useState<NFT[]>([])

  useEffect(() => {
    if (address && isPageVisible) {
      getNFTs({ apiUrl, address, contractAddress, setNFTs, loading, setLoading, refreshMetadata })
    }
  }, [address, refreshMetadata, isPageVisible])

  function sendRequest() {
    if (address) {
      getNFTs({ apiUrl, address, contractAddress, setNFTs, loading, setLoading, refreshMetadata })
    }
  }

  shared.useInterval(sendRequest, isPageVisible ? 10000 : 30000)

  return { loading, nfts }
}

async function getNFTs({
  apiUrl,
  address,
  contractAddress,
  setNFTs,
  loading,
  setLoading,
  refreshMetadata,
}: {
  apiUrl: string
  address: string
  contractAddress: string
  refreshMetadata: boolean
  setNFTs: (nfts: any) => void
  loading: boolean
  setLoading: (loading: boolean) => void
}) {
  try {
    const response = await fetch(
      `${apiUrl}?address=${address}&contract=${contractAddress}&refreshMetadata=${refreshMetadata}`
    )

    if (response.ok) {
      const nfts: NFT[] = await response.json()
      setNFTs(nfts.sort((nftA, nftB) => nftB.tokenId - nftA.tokenId))
    }
  } catch (error) {
    console.error('Error while getting NFTs', error)
  } finally {
    if (loading) setLoading(false)
  }
}
