import styled from 'styled-components/macro'
import Countdown, { CountdownRenderProps } from 'react-countdown'

import { shared } from '@packages/ui'

import { FONTS } from '../../shared/constants'

const { BREAKPOINTS, Z_INDEX } = shared

interface Props {
  mintLaunchUnixMs: number
}

export function MintCountdown({ mintLaunchUnixMs }: Props) {
  function countdownRenderer({ days, hours, minutes, seconds, completed }: CountdownRenderProps) {
    return (
      <>
        <CountdownNumbers>
          {days.toString().padStart(2, '0')}:{hours.toString().padStart(2, '0')}:
          {minutes.toString().padStart(2, '0')}:{seconds.toString().padStart(2, '0')}
        </CountdownNumbers>
        {completed && (
          <CountdownCompleted>
            Minting is live, please wait for the page to refresh
          </CountdownCompleted>
        )}
      </>
    )
  }

  return (
    <Container>
      <CountdownPreText>The journey begins on September 18th</CountdownPreText>
      <CountdownNumbers>
        <Countdown date={mintLaunchUnixMs} renderer={countdownRenderer} key={mintLaunchUnixMs} />
      </CountdownNumbers>
    </Container>
  )
}

const Container = styled.div`
  z-index: ${Z_INDEX.content};
  -webkit-transform: translate3d(0, 0, 0); // required for safari
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 15px;
  padding: 20px 20px 35px 20px;
  font-family: ${FONTS.logo};
  @media only screen and (max-width: ${BREAKPOINTS.small}) {
    padding: 20px;
  }
`

const CountdownNumbers = styled.div`
  color: rgb(255 0 0);
  font-size: 7vw;
  line-height: 6vw;
  -webkit-text-stroke: 2px white;
  @media only screen and (max-width: ${BREAKPOINTS.medium}) {
    font-size: 12vw;
    line-height: 10vw;
  }
`

const CountdownPreText = styled.div`
  font-family: ${FONTS.title};
  color: white;
  font-size: 1.8vw;
  @media only screen and (max-width: ${BREAKPOINTS.medium}) {
    font-size: 5vw;
  }
`

const CountdownCompleted = styled.div`
  font-family: ${FONTS.title};
  color: white;
  font-size: 1.8vw;
  @media only screen and (max-width: ${BREAKPOINTS.medium}) {
    font-size: 3vw;
  }
  @media only screen and (max-width: ${BREAKPOINTS.small}) {
    font-size: 4vw;
  }
`
