import styled from 'styled-components'

import { SpinAnimationContainer } from './spinAnimation'
import { ReactComponent as SadPepe } from '../assets/pepe-sad.svg'

export function LoadingPepe() {
  return (
    <SpinAnimationWrapper>
      <SadPepe />
    </SpinAnimationWrapper>
  )
}

const SpinAnimationWrapper = styled(SpinAnimationContainer)`
  margin-bottom: 11px;
`
