import styled from 'styled-components'
import { useAccount, useDisconnect } from 'wagmi'

import { BREAKPOINTS, COLORS, Button, StyledComponentProps } from '@packages/ui/src/shared'

import { formatShortAddress } from '../utils'

export function Connected({ className }: StyledComponentProps) {
  const { address } = useAccount()
  const { disconnect } = useDisconnect()

  return (
    <Container className={className}>
      <Address>{formatShortAddress(address ?? null)}</Address>
      <Disconnect onClick={() => disconnect()}>⨉</Disconnect>
    </Container>
  )
}

const Container = styled(Button)`
  padding: 12px 20px;
  background-color: ${COLORS.mcPepeYellow};
  cursor: auto;
  &:hover {
    opacity: 1;
  }
  @media only screen and (max-width: ${BREAKPOINTS.medium}) {
    font-size: 14px;
    padding: 10px 14px;
  }
`

const Address = styled.span``

const Disconnect = styled.span`
  margin-left: 6px;
  font-weight: 700;
  cursor: pointer;
  position: relative;
  top: -1px;
`
