export const Z_INDEX = {
  background: -1,
  headerLayer1: 1,
  headerLayer2: 2,
  headerLayer3: 3,
  content: 4,
  recaptchaBadge: 5,
  priorityContent: 6,
  modal: 7,
  walletModal: 8,
}
