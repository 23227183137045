import { useEffect, useRef, useState } from 'react'
import ReactModal from 'react-modal'
import styled from 'styled-components/macro'

import { SignatureData, UserOrderResponse, CompletedOrder } from '@packages/interfaces'
import { api, shared } from '@packages/ui'

import { API_URL } from 'src/settings'
import { API_ENDPOINT } from 'src/apiEndpoints'
import { COLORS, FONTS } from 'src/shared/constants'
import { WideModalElementWrapper, innerWideModalScreenStyles } from 'src/shared/components'

import LoadingImage from '../assets/dogelon-loading.svg'
import { OrderSession } from '../interfaces'

const MAX_USER_PROMPT_DISPLAY_LENGTH = 120
const MAX_USER_PROMPT_MOBILE_DISPLAY_LENGTH = 70
const IMAGE_GENERATED_ANIMATION_LENGTH_SECONDS = 3.5

const { BREAKPOINTS } = shared

function trimPrompt(userPrompt: string, maxLength: number) {
  if (userPrompt.length > maxLength) {
    return userPrompt.substring(0, maxLength) + '...'
  }
  return userPrompt
}

interface Props extends shared.ModalProps {
  orderSession?: OrderSession
  onCompletedOrder: (completedOrder: CompletedOrder, signatureData?: SignatureData) => void
  onError: (error: api.ApiErrorResponse) => void
}

export function OrderReceiptModal({
  isModalOpen,
  closeModal,
  orderSession,
  onCompletedOrder,
  onError,
  className,
}: Props) {
  const { width } = shared.useWindowDimensions()
  const isModalOpenRef = useRef<boolean>(false) // need useRef as the recursive subscribeToOrderEvents call scopes the old state value
  const [orderCompleted, setOrderCompleted] = useState(false)

  useEffect(() => {
    isModalOpenRef.current = isModalOpen
  }, [isModalOpen])

  useEffect(() => {
    if (orderSession) {
      setOrderCompleted(false)
      subscribeToOrderEvents()
    }
  }, [orderSession])

  function handleClose() {
    closeModal()
  }

  async function subscribeToOrderEvents() {
    try {
      const response = await api.apiFetch(
        `${API_URL}${API_ENDPOINT.GET.generateImageStatus}?sessionId=${orderSession?.sessionId}`
      )
      const json = await response.json()

      if (response.status === 200) {
        const userOrderResponse: UserOrderResponse = json

        if (userOrderResponse.order) {
          setOrderCompleted(true)
          setTimeout(
            () =>
              onCompletedOrder(
                userOrderResponse.order as CompletedOrder,
                userOrderResponse.signatureData
              ),
            IMAGE_GENERATED_ANIMATION_LENGTH_SECONDS * 1000
          )
        } else if (userOrderResponse.error) {
          console.error('image error event', userOrderResponse.error)
          onError({
            name: 'ModelServerError',
            message: 'Error while processing image',
          })
        } else {
          throw new Error('Invalid image response: ' + userOrderResponse)
        }
      } else if (json && api.isApiErrorResponse(json)) {
        if (json.name === 'OrderPollingTimeoutError') {
          if (isModalOpenRef.current) {
            await subscribeToOrderEvents()
          }
        } else {
          onError(json)
        }
      } else {
        throw new Error(json)
      }
    } catch (error) {
      console.error(error)
      onError({ name: 'UnknownError' })
    }
  }

  if (!orderSession) return <></>

  const isMobile = width < parseInt(BREAKPOINTS.medium)

  let userPrompt = orderSession.userPrompt
  if (isMobile) {
    userPrompt = trimPrompt(userPrompt, MAX_USER_PROMPT_MOBILE_DISPLAY_LENGTH)
  } else {
    userPrompt = trimPrompt(userPrompt, MAX_USER_PROMPT_DISPLAY_LENGTH)
  }

  return (
    <ReactModal
      isOpen={isModalOpen}
      onRequestClose={handleClose}
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick={false}
      closeTimeoutMS={217}
      ariaHideApp={false}
      className='modalElement'
      contentElement={(props, children) => (
        <WideModalElementWrapper {...props}>{children}</WideModalElementWrapper>
      )}
      overlayElement={(props, contentElement) => (
        <ModalPortalWrapper {...props}>
          <shared.OverlayElement className={`modelOverlay ${className}`}>
            {contentElement}
          </shared.OverlayElement>
        </ModalPortalWrapper>
      )}
    >
      <shared.ModalContainer>
        <Container>
          <Title>Sending Transmission #{orderSession.orderId}</Title>

          <ReceiptDetails>
            <ReceiptLineItem
              id='quantum'
              animationDuration={3}
              animationDelay={0}
              width={isMobile ? 250 : 370}
            >
              &gt; Initiating quantum entanglement...
            </ReceiptLineItem>
            <ReceiptLineItem
              id='connection'
              animationDuration={2.5}
              animationDelay={3}
              width={isMobile ? 175 : 260}
            >
              &gt; Connection Established!
            </ReceiptLineItem>
            <ReceiptLineItem
              id='processing'
              animationDuration={2.5}
              animationDelay={5.5}
              width={isMobile ? 200 : 290}
              displayCursor={!orderCompleted}
            >
              &gt; Processing your request...
            </ReceiptLineItem>

            {orderCompleted && (
              <ReceiptLineItem
                id='image'
                animationDuration={IMAGE_GENERATED_ANIMATION_LENGTH_SECONDS - 1}
                animationDelay={0}
                width={isMobile ? 210 : 310}
                displayCursor
              >
                &gt; Image generation complete...
              </ReceiptLineItem>
            )}
            <ImageContainer>
              <SpinningLoadingImage src={LoadingImage} />
            </ImageContainer>
          </ReceiptDetails>
        </Container>
      </shared.ModalContainer>
    </ReactModal>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  display-contents: center;
  align-items: center;
  gap: 10px;
  font-family: ${FONTS.text};
  font-size: 16px;
  color: ${COLORS.modalFont};
  width: 100%;
  height: 100%;
  background-color: ${COLORS.black};
  padding: 10px;
  overflow: hidden;
  ${innerWideModalScreenStyles}
`

const Title = styled.div`
  text-transform: uppercase;
  font-weight: 500;
  flex-direction: column;
  font-size: 35px;
  @media only screen and (max-width: ${BREAKPOINTS.medium}) {
    font-size: 20px;
  }
`

const ReceiptDetails = styled.div`
  width: 100%;
  gap: 5px;
  display: flex;
  flex-direction: column;
  font-size: 25px;
  line-height: 30px;
  @media only screen and (max-width: ${BREAKPOINTS.medium}) {
    font-size: 17px;
    line-height: 17px;
  }
`

const ReceiptLineItem = styled.div<{
  id: string
  animationDuration: number
  animationDelay: number
  width: number
  displayCursor?: boolean
}>`
  width: 100%;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  transform: translateY(-50%);
  border-right: 2px solid transparent;
  animation: ${(props) => `typewriter${props.id}`} ${(props) => props.animationDuration}s steps(44)
      ${(props) => props.animationDelay}s 1 normal both,
    1s ${(props) => `startTextCursor${props.id}`} ${(props) => props.animationDelay}s forwards,
    ${(props) => `blinkTextCursor${props.id}`} 650ms steps(44)
      ${(props) =>
        props.animationDelay +
        props.animationDuration +
        (props.displayCursor ? 0 : Number.MAX_SAFE_INTEGER)}s
      infinite normal,
    1s ${(props) => `stopTextCursor${props.id}`}
      ${(props) =>
        props.animationDelay +
        props.animationDuration +
        (props.displayCursor ? Number.MAX_SAFE_INTEGER : 0)}s
      forwards;

  @keyframes typewriter${(props) => props.id} {
    from {
      width: 0;
    }
    to {
      width: ${(props) => props.width}px;
    }
  }

  @keyframes blinkTextCursor${(props) => props.id} {
    from {
      border-right-color: ${COLORS.buttons.background};
    }
    to {
      border-right-color: transparent;
    }
  }

  @keyframes startTextCursor${(props) => props.id} {
    from {
      border-right-color: transparent;
    }
    to {
      border-right-color: ${COLORS.buttons.background};
    }
  }

  @keyframes stopTextCursor${(props) => props.id} {
    from {
      border-right-color: ${COLORS.buttons.background};
    }
    to {
      border-right-color: transparent;
    }
  }
`

const ModalPortalWrapper = styled(shared.ModalPortal)`
  &.ReactModal__Overlay--before-close {
    .modelOverlay {
      animation: none;
    }
  }
`

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const SpinningLoadingImage = styled.img`
  height: 100px;

  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
  animation: spin 3s infinite linear;
  position: absolute;
  bottom: 30px;
  @media only screen and (max-width: ${BREAKPOINTS.medium}) {
    bottom: 50px;
  }
`
