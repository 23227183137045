import styled from 'styled-components'

import { StyledComponentProps } from '../interfaces'

interface Props extends StyledComponentProps {
  src: string
  autoPlay?: boolean
  id?: string
}

export function Video({ src, autoPlay = false, id, className }: Props) {
  return (
    <VideoContainer
      src={src}
      loop
      muted
      playsInline
      className={className}
      id={id}
      autoPlay={autoPlay}
    >
      Your browser does not support the video tag.
    </VideoContainer>
  )
}

const VideoContainer = styled.video`
  height: 25vw;
  border-radius: 4px;
`
