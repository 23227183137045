import styled from 'styled-components'

import { BREAKPOINTS } from '../constants'

export const A = styled.a`
  font-weight: 400;
  font-size: 18px;
  color: #ffffff;
  transition: 0.2s;
  &:hover {
    opacity: 0.7;
  }
  @media only screen and (max-width: ${BREAKPOINTS.medium}) {
    font-size: 16px;
  }
`
