import styled from 'styled-components'

import { TransactionError as _TransactionError } from '@packages/web3'
import { shared } from '@packages/ui'

import { COLORS, FONTS } from '../constants'

const { BREAKPOINTS } = shared

export const TransactionError = styled(_TransactionError)`
  font-size: 22px;
  line-height: 22px;
  span {
    font-size: 25px;
    line-height: 25px;
  }
  p {
    font-size: 25px;
    line-height: 25px;
    padding-top: 20px;
  }
  svg {
    fill: ${COLORS.modalFont};
  }
  button {
    font-size: 25px;
    color: ${COLORS.modalFont};
    font-family: ${FONTS.text};
    background-color: transparent;
    border: 2px solid ${COLORS.modalFont};
    border-radius: 0px;
    box-shadow: none;
  }

  @media only screen and (max-width: ${BREAKPOINTS.medium}) {
    p {
      font-size: 25px;
      line-height: 25px;
    }
  }
`
