import styled from 'styled-components'
import { useEffect, useState } from 'react'

import { ReactComponent as Plus } from '../assets/plus.svg'
import { ReactComponent as Minus } from '../assets/minus.svg'
import { COLORS } from '../constants'
import { StyledComponentProps } from '../interfaces'

interface Props extends StyledComponentProps {
  value: number
  onChange: (num: number) => void
  min?: number
  max?: number
}

export function NumberInput({ value, onChange, min = 0, max = Number.MAX_SAFE_INTEGER }: Props) {
  const [localValue, setLocalValue] = useState(value)

  useEffect(() => {
    setLocalValue(value)
  }, [value])

  function handleOnBlur() {
    if (localValue >= min && localValue <= max) {
      onChange(localValue)
    } else if (localValue < min) {
      onChange(min)
    } else if (localValue > max) {
      onChange(max)
    }
  }

  function handleOnChange(event: React.ChangeEvent<HTMLInputElement>) {
    const value = parseInt(event.target.value)
    setLocalValue(value)
  }

  function onAdd() {
    if (localValue < max) {
      onChange(value + 1)
    }
  }

  function onSubtract() {
    if (localValue > min) {
      onChange(value - 1)
    }
  }

  return (
    <Container>
      <Button onClick={onSubtract}>
        <Minus width={25} height={25} />
      </Button>
      <Input
        type='number'
        value={localValue}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
      ></Input>
      <Button onClick={onAdd}>
        <Plus width={25} height={25} />
      </Button>
    </Container>
  )
}

const Container = styled.div`
  height: 55px;
  border-radius: 50px;
  background-color: white;
  display: flex;
  width: 100px;
`

const Input = styled.input`
  padding: 15px 0px;
  border-radius: 50px;
  font-family: 'IntroBold', sans-serif;
  font-size: 18px;
  font-weight: 400;
  color: ${COLORS.black};
  height: 100%;
  width: 100%;
  background-color: transparent;
  border: none;
  text-align: center;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield;

  &:focus {
    background-color: white;
    outline: none;
  }
`

const Button = styled.button`
  background-color: transparent;
  font-weight: 700;
  font-size: 30px;
  width: 100px;
  padding: 1px 6px;
`
