const getOffsetTop = (element: HTMLElement | null) => {
  let offsetTop = 0
  let currentElement: any = element
  while (currentElement) {
    offsetTop += currentElement.offsetTop
    currentElement = currentElement.offsetParent
  }
  return offsetTop
}

export function scrollToHash(hashId: string, offset = 0) {
  const elementToScroll = document.getElementById(hashId.replace('#', ''))
  if (!elementToScroll) return
  window.scrollTo({
    top: getOffsetTop(elementToScroll) + offset,
    behavior: 'smooth',
  })
}
