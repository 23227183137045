import styled, { css } from 'styled-components'
import Chance from 'chance'

import { ReactComponent as ConfettiImage } from '../assets/confetti.svg'

const DEFAULT_CONFETTI_N = 16
const chance = new Chance()

interface Props {
  amount?: number
  color?: string
}

export function Confetti({ color = 'gold', amount = DEFAULT_CONFETTI_N }: Props) {
  return (
    <ConfettiContainer amount={amount}>
      {Array.from(Array(amount).keys()).map((item, index) => {
        return <ConfettiImage fill={color} id={`${index}`} />
      })}
    </ConfettiContainer>
  )
}

function createConfettiCSS(amount: number) {
  let styles = ''

  for (let i = 0; i < amount; i += 1) {
    styles += `
      .confetti:nth-child(${i}) {
        width: 18px;
        height: 18px;
        left:${
          i % 2 === 0 ? chance.integer({ min: 4, max: 35 }) : chance.integer({ min: 65, max: 92 })
        }%; 
        top: ${chance.integer({ min: 5, max: 75 })}%;
      }

      .confetti:nth-child(${i}) {
        animation-delay: ${chance.floating({ min: 0.2, max: 4, fixed: 2 })}s;
      }
     `
  }

  return css`
    ${styles}
  `
}

export const ConfettiContainer = styled.div<{ amount: number }>`
  width: 100%;
  height: 100%;
  margin-bottom: -10px;
  @keyframes grow {
    0%,
    100% {
      transform: scale(0);
    }
    50% {
      transform: scale(1);
    }
  }
  .confetti {
    position: absolute;
    animation: grow 2s cubic-bezier(0.42, 0, 0.275, 1.155) infinite both;
  }
  ${(props) => createConfettiCSS(props.amount)};
`
