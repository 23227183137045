import styled from 'styled-components'
import { useWeb3Modal } from '@web3modal/react'

import { COLORS, BREAKPOINTS, Button, StyledComponentProps } from '@packages/ui/src/shared'

export function Connect({ className }: StyledComponentProps) {
  const { open } = useWeb3Modal()

  return (
    <Container className={className}>
      <ConnectButton onClick={open}>Connect Wallet</ConnectButton>
    </Container>
  )
}

const Container = styled.div`
  @media only screen and (max-width: ${BREAKPOINTS.medium}) {
    margin-top: 0px;
  }
`

const ConnectButton = styled(Button)`
  padding: 12px 20px;
  background-color: ${COLORS.mcPepeYellow};
  @media only screen and (max-width: ${BREAKPOINTS.medium}) {
    font-size: 14px;
    padding: 10px 14px;
  }
`
