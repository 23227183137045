import styled, { css } from 'styled-components/macro'
import { useEffect, useState } from 'react'
import { ContractTransactionResponse } from 'ethers'
import ReactModal from 'react-modal'
import { useAutoAnimate } from '@formkit/auto-animate/react'
import { useWeb3Modal } from '@web3modal/react'
import { useAccount, useNetwork, useSwitchNetwork } from 'wagmi'

import {
  SignatureData,
  OrderAttestationRequest,
  CompletedOrder,
  MintImageAttestationPayload,
} from '@packages/interfaces'
import { api, shared, nfts } from '@packages/ui'
import { parseBlockchainError, useContract } from '@packages/web3'

import {
  NFT_BASE_URL,
  DEPLOYED_CHAIN_ID,
  ERC721_MINTER_CONTRACT_ADDRESS,
  API_URL,
  RECAPTCHA_V2_SITE_KEY,
  ERC721_CONTRACT_ADDRESS,
  LINKS,
} from 'src/settings'
import erc721MinterAbi from 'src/web3/abis/ERC721DogelonMinter.json'
import erc721Abi from 'src/web3/abis/ERC721Dogelon.json'
import { COLORS, FONTS } from 'src/shared/constants'
import { API_ENDPOINT } from 'src/apiEndpoints'
import {
  ButtonWithCancel,
  ModalButton,
  ModalCloseButton,
  ModalElementWrapper,
  TransactionError,
  innerWideModalScreenStyles,
} from 'src/shared/components'
import { getOrderNFTImageHeight } from 'src/shared/utils'

import { OrderErrorMessage } from './OrderErrorMessage'

const { BREAKPOINTS, Z_INDEX } = shared

type Phase =
  | 'confirm'
  | 'connecting'
  | 'switching-network'
  | 'attestation'
  | 'recaptcha'
  | 'signing'
  | 'minting'
  | 'tx-error'
  | 'error'
  | 'success'

interface Props extends shared.ModalProps {
  order: CompletedOrder
  signatureData?: SignatureData
  onReorder?: () => Promise<void>
  displayOverlay: boolean
}

export function OrderMintingModal({
  isModalOpen,
  closeModal,
  order,
  signatureData: _signatureData,
  onReorder,
  displayOverlay,
}: Props) {
  const grecaptcha = api.useInstanceLoader(RECAPTCHA_V2_SITE_KEY)
  const [displayReCaptchaV2Checkbox, setDisplayReCaptchaV2Checkbox] = useState(false)
  const fetchWithReCaptcha = api.useFetchWithReCaptcha('signature')

  const [parent] = useAutoAnimate()
  const { width } = shared.useWindowDimensions()

  const { open } = useWeb3Modal()
  const { address } = useAccount()
  const { chain } = useNetwork()
  const { switchNetwork } = useSwitchNetwork({
    onError() {
      setPhase('confirm')
    },
  })

  const [minter] = useContract({
    address: ERC721_MINTER_CONTRACT_ADDRESS,
    abi: erc721MinterAbi,
    requireSigner: true,
    deployedChainId: DEPLOYED_CHAIN_ID,
  })
  const [erc721] = useContract({
    address: ERC721_CONTRACT_ADDRESS,
    abi: erc721Abi,
    requireSigner: false,
    deployedChainId: DEPLOYED_CHAIN_ID,
  })

  const [tokenId, setTokenId] = useState<number>()
  const [signatureData, setSignatureData] = useState<SignatureData>()
  const [tx, setTx] = useState<ContractTransactionResponse>()
  const [error, setError] = useState<api.ApiErrorResponse>()
  const [txError, setTxError] = useState<string>()
  const [phase, setPhase] = useState<Phase>('confirm')
  const [reordering, setReordering] = useState(false)

  useEffect(() => {
    if (_signatureData) {
      setSignatureData(_signatureData)
    }
  }, [_signatureData])

  useEffect(() => {
    console.log('phase', phase)
    if (
      (phase === 'connecting' || phase === 'switching-network' || phase === 'attestation') &&
      address &&
      minter &&
      erc721
    ) {
      handleMint()
    }
  }, [address, minter, erc721])

  function resetData() {
    setPhase('confirm')
    setSignatureData(undefined)
    setTx(undefined)
    setError(undefined)
    setTxError(undefined)
    setTokenId(undefined)
    setDisplayReCaptchaV2Checkbox(false)
  }

  async function onReCaptchaV2CheckboxChange(token: string | null) {
    if (token) {
      setDisplayReCaptchaV2Checkbox(false)
      setPhase('signing')
      // delay submission so that we dont get rate limited
      await new Promise((r) => setTimeout(r, 2000))
      await handleMint(token)
    }
  }

  function isMintButtonDisabled() {
    return displayReCaptchaV2Checkbox
  }

  function handleClose() {
    closeModal()
    resetData()
  }

  async function handleReorder() {
    if (!onReorder) return
    setReordering(true)
    await onReorder()
    resetData()
    setReordering(false)
  }

  function handleCancel() {
    resetData()
  }

  function handleTryAgainOnError() {
    resetData()
  }

  async function setErrorPhase(error: api.ApiErrorResponse) {
    setError(error)
    setPhase('error')
  }

  async function setTxErrorPhase(error: any) {
    let parsedError = parseBlockchainError(error)
    if (parsedError.includes('hash exists')) {
      parsedError = 'This image has already been minted'
    }
    setTxError(parsedError)
    setPhase('tx-error')
  }

  async function handleMint(reCaptchaV2Token?: string) {
    if (
      !(
        phase === 'confirm' ||
        phase === 'connecting' ||
        phase === 'switching-network' ||
        phase === 'attestation' ||
        phase === 'signing' ||
        reCaptchaV2Token
      )
    ) {
      console.debug('returning')
      return
    }

    if (!address) {
      open()
      setPhase('connecting')
      console.debug('connecting')
      return
    }

    if (chain?.id !== DEPLOYED_CHAIN_ID && switchNetwork) {
      try {
        await switchNetwork(DEPLOYED_CHAIN_ID)
        setPhase('switching-network')
        console.debug('switching')
        return
      } catch (error) {
        console.error(error)
        setErrorPhase({ name: 'UnknownError' })
        return
      }
    }

    let data = signatureData
    if (!data) {
      setPhase('attestation')
      console.debug('attestation')
      try {
        const request: OrderAttestationRequest = {
          address,
          imageHash: order.finalImageHash,
          orderId: order.id,
        }

        const response = await fetchWithReCaptcha(
          API_URL + API_ENDPOINT.POST.signature,
          request,
          reCaptchaV2Token
        )
        const json = await response.json()

        if (response.status === 200) {
          data = json
        } else if (json && api.isApiErrorResponse(json)) {
          if (json.name === 'ReCaptchaV3FailedError') {
            setDisplayReCaptchaV2Checkbox(true)
            setPhase('recaptcha')
          } else {
            setErrorPhase(json)
          }
          return
        } else {
          throw new Error('Unable to get attestation signature')
        }
      } catch (error: any) {
        console.error(error)
        setErrorPhase({ name: 'UnknownError' })
        return
      }
    }

    if (!data) {
      setErrorPhase({ name: 'UnknownError' })
      return
    }

    if (!minter) {
      // minter object isnt created yet, will set phase to connecting
      // and useEffect above will recall handleMint once the minter object is set
      setPhase('connecting')
      console.debug('connecting no minter')
      return
    }

    let _tx
    setPhase('signing')
    try {
      const price = await erc721?.price()
      console.debug('minting', data, price)

      let whitelistQuantity = 0
      let merkleProof = []

      const whitelistEnabled = await erc721?.whitelistMintingEnabled()
      if (whitelistEnabled) {
        const merkleProofResponse = await api.apiFetch(
          API_URL +
            API_ENDPOINT.GET.merkleProof +
            `?contract=${ERC721_CONTRACT_ADDRESS}&type=concurrent&address=${address}`
        )
        if (merkleProofResponse.status === 200) {
          const json = await merkleProofResponse.json()
          const whitelistMintCount = await erc721?.whitelistMintCounts(address)
          if (whitelistMintCount < json.quantity) {
            whitelistQuantity = json.quantity
            merkleProof = json.proof
            console.debug('using whitelist', whitelistQuantity, merkleProof)
          }
        }
      }

      _tx = await minter.mint(
        data?.message.timestamp,
        (data?.message as MintImageAttestationPayload).imageHash,
        data?.signature,
        merkleProof,
        whitelistQuantity,
        {
          value: price,
        }
      )
    } catch (error: any) {
      console.error(error)
      setTxErrorPhase(error)
      return
    }

    setTx(_tx)
    setPhase('minting')
    try {
      const receipt = await _tx.wait()
      const log = receipt?.logs?.[0]
      const hexString = log && log.topics.length > 3 ? log.topics[3] : ''
      const bn = BigInt(hexString)
      setTokenId(parseInt(bn.toString()))
    } catch (error: any) {
      console.error(error)
      if (error.code === 'TRANSACTION_REPLACED' && error.reason === 'repriced') {
        setTx(error.replacement)
      } else {
        console.error(error)
        setTxErrorPhase(error)
        return
      }
    }
    setPhase('success')
  }

  if (!(isModalOpen && order)) return null

  let buttonText

  switch (phase) {
    case 'connecting':
    case 'switching-network':
    case 'signing':
      buttonText = 'See Wallet'
      break
    case 'attestation':
      buttonText = 'Preparing'
      break
    case 'recaptcha':
      buttonText = 'ReCAPTCHA'
      break
    default:
      buttonText = 'Mint'
  }

  const canClose =
    phase === 'confirm' ||
    phase === 'connecting' ||
    phase === 'switching-network' ||
    phase === 'recaptcha' ||
    phase === 'success' ||
    phase === 'error' ||
    phase === 'tx-error'

  const canCancel =
    phase === 'connecting' ||
    phase === 'switching-network' ||
    phase === 'attestation' ||
    phase === 'recaptcha' ||
    phase === 'signing'

  return (
    <ReactModal
      isOpen={isModalOpen}
      onRequestClose={handleClose}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
      ariaHideApp={false}
      className='modalElement'
      contentElement={(props, children) => (
        <ModalElementWrapper {...props}>{children}</ModalElementWrapper>
      )}
      overlayElement={(props, contentElement) => {
        const overlayProps = { ...props, displayOverlay }
        return (
          <ModalPortalWrapper {...overlayProps}>
            <shared.OverlayElement className='modelOverlay'>{contentElement}</shared.OverlayElement>
          </ModalPortalWrapper>
        )
      }}
    >
      <shared.ModalContainer>
        <Container>
          {(phase === 'confirm' ||
            phase === 'connecting' ||
            phase === 'switching-network' ||
            phase === 'recaptcha' ||
            phase === 'signing' ||
            phase === 'attestation') && (
            <MintContainer ref={parent}>
              <Img
                src={nfts.transformNFTImageUrl(order.uncompressedImageUrl, NFT_BASE_URL)}
                loadingHeight={`${getOrderNFTImageHeight(width)}px`}
              />
              {displayReCaptchaV2Checkbox && (
                <api.ReCaptchaCheckbox
                  onChange={onReCaptchaV2CheckboxChange}
                  siteKey={RECAPTCHA_V2_SITE_KEY}
                  grecaptcha={grecaptcha}
                />
              )}
              <ButtonsContainer>
                {onReorder && !canCancel && (
                  <RedoButtonContainer>
                    <ModalButton loading={reordering} onClick={handleReorder}>
                      Redo
                    </ModalButton>
                  </RedoButtonContainer>
                )}
                <MintButton
                  onClick={() => handleMint()} // need wrapper as it keeps calling handleMint with the button as the first arg
                  disabled={isMintButtonDisabled()}
                  canCancel={canCancel}
                  onCancel={handleCancel}
                  cancelText='X'
                >
                  {buttonText}
                </MintButton>
              </ButtonsContainer>
              {canClose && <ModalCloseButton onClick={handleClose}>EXIT</ModalCloseButton>}
            </MintContainer>
          )}

          {phase === 'minting' && (
            <MintingContainer>
              <Img
                src={nfts.transformNFTImageUrl(order.uncompressedImageUrl, NFT_BASE_URL)}
                showLoadingAnimation
                loadingHeight={`${getOrderNFTImageHeight(width)}px`}
              />
              <Title showLoadingAnimation>Minting Generation {order.id}</Title>
              <P center>Please wait while the transaction confirms.</P>
            </MintingContainer>
          )}

          {phase === 'success' && (
            <MintingContainer>
              <Img
                src={nfts.transformNFTImageUrl(order.uncompressedImageUrl, NFT_BASE_URL)}
                loadingHeight={`${getOrderNFTImageHeight(width)}px`}
              />
              <Title>Minted</Title>
              <ButtonsContainer>
                <RedoButtonContainer>
                  <ModalButton loading={reordering} onClick={handleReorder}>
                    Redo
                  </ModalButton>
                </RedoButtonContainer>
                <Link
                  href={nfts.getOpenSeaNFTUrl(
                    chain?.id ?? 1,
                    tokenId ?? 0,
                    ERC721_CONTRACT_ADDRESS
                  )}
                  target='_blank'
                  rel='noreferrer'
                >
                  <ModalButton>OpenSea</ModalButton>
                </Link>
              </ButtonsContainer>
              <ButtonsContainer>
                <TwitterShareLink
                  tweetText={'Behold my creation using ' + LINKS.twitterUsername}
                  imageUrl={
                    LINKS.embeddedImageUrl + nfts.getImageFileName(order.uncompressedImageUrl)
                  }
                />
                {canClose && <ModalCloseButton onClick={handleClose}>EXIT</ModalCloseButton>}
              </ButtonsContainer>
            </MintingContainer>
          )}

          {phase === 'error' && (
            <MintingContainer>
              {error ? (
                <OrderErrorMessage
                  error={error}
                  onTryAgain={handleTryAgainOnError}
                  orderId={order.id}
                />
              ) : (
                <div>Error</div>
              )}
              {canClose && <ModalCloseButton onClick={handleClose}>EXIT</ModalCloseButton>}
            </MintingContainer>
          )}

          {phase === 'tx-error' && (
            <MintingContainer>
              {txError === 'max general supply reached' ? (
                <ErrorContainer>
                  Max general supply reached. Please wait for the whitelist minting period to be
                  over for any remaining mint spots.
                  <ModalButton onClick={handleTryAgainOnError}>Retry</ModalButton>
                </ErrorContainer>
              ) : (
                <TransactionError txError={txError} tx={tx} onTryAgain={handleTryAgainOnError} />
              )}

              {canClose && <ModalCloseButton onClick={handleClose}>EXIT</ModalCloseButton>}
            </MintingContainer>
          )}
        </Container>
      </shared.ModalContainer>
    </ReactModal>
  )
}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 10px;
  padding-top: 20px;
  @media only screen and (max-width: ${BREAKPOINTS.medium}) {
    padding-top: 8px;
    padding-bottom: 12px;
  }
  color: ${COLORS.modalFont};
  font-family: ${FONTS.text};
  background-color: ${COLORS.black};
  ${innerWideModalScreenStyles}
`

const MintContainer = styled.div<{ ref: any }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 8px;
  font-family: ${FONTS.text};
`

const MintingContainer = styled.div`
  min-height: 300px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-family: ${FONTS.text};
`

const Title = styled(shared.Title)<{ showLoadingAnimation?: boolean }>`
  text-align: center;
  font-size: 40px;
  line-height: 38px;
  font-family: ${FONTS.text};
  @media only screen and (max-width: ${BREAKPOINTS.medium}) {
    font-size: 30px;
    line-height: 30px;
  }
`

const P = styled.p<{ bold?: boolean; center?: boolean }>`
  width: 100%;
  font-size: 22px;
  line-height: 20px;
  ${({ bold }) => (bold ? 'font-weight: bold' : '')};
  ${({ center }) => (center ? 'text-align: center' : '')};
`

const Img = styled(shared.GracefullyLoadedImg)<{
  showLoadingAnimation?: boolean
}>`
  max-width: 400px;
  border-radius: 10px;
  margin-bottom: 10px;
  img {
    object-fit: contain;
    max-width: 400px;
    background: ${COLORS.black};
    border-radius: 10px;
    z-index: ${Z_INDEX.modal};
  }

  ${({ showLoadingAnimation = false }) =>
    showLoadingAnimation &&
    css`
      ${shared.getImageLoadingAnimation(
        COLORS.modalFont,
        COLORS.buttons.border,
        COLORS.buttons.background
      )}
      &::after {
        top: 3%;
        z-index: ${Z_INDEX.content};
      }
    `}
`

const ButtonsContainer = styled.div`
  gap: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 400px;
  font-size: 27px;
  line-height: 27px;
  @media only screen and (max-width: ${BREAKPOINTS.medium}) {
    gap: 10px;
  }
`

const RedoButtonContainer = styled.div`
  flex: 1;
  display: flex;
  button {
    flex: 1;
  }
`

const Link = styled(shared.A)`
  flex: 1;
  width: 100%;
  button {
    width: 100%;
    color: ${COLORS.white};
  }
`

const MintButton = styled(ButtonWithCancel)<{ loading?: boolean }>`
  ${(props) =>
    props.loading &&
    css`
      margin-left: 40%;
      background-color: ${COLORS.black};
      z-index: ${Z_INDEX.content};
      font-size: 27px;
      line-height: 27px;
      &:hover {
        opacity: 1;
      }
      div {
        width: 22px;
        height: 22px;
      }
      @media only screen and (max-width: ${BREAKPOINTS.medium}) {
        height: 41px;
        margin-left: 42%;
        font-size: 21px;
        line-height: 21px;
      }
    `}
`

const TwitterShareLink = styled(shared.TwitterShareLink)`
  font-family: ${FONTS.text};
  font-size: 25px;
  line-height: 25px;
`

const ModalPortalWrapper = styled(shared.ModalPortal)<{ displayOverlay: boolean }>`
  ${(props) =>
    !props.displayOverlay &&
    css`
      &.ReactModal__Overlay--after-open {
        .modelOverlay {
          animation: none;
        }
      }
    `}
`

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 5px;
  font-size: 24px;
  line-height: 22px;
`
