import { BrowserRouter as Router } from 'react-router-dom'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { useWeb3Modal, Web3Modal } from '@web3modal/react'
import { useAccount } from 'wagmi'
import React, { useEffect } from 'react'
import styled, { createGlobalStyle, css } from 'styled-components'

import { shared, api } from '@packages/ui'

import { PageRoutes, Navbar, Footer } from './pages'
import { RECAPTCHA_V3_SITE_KEY, WALLET_CONNECT_PROJECT_ID } from './settings'
import { ethereumClient, getDefaultChain } from './web3'

const Providers = shared.nestComponents(Router)

const GlobalStyles = createGlobalStyle`
  .grecaptcha-badge {
    bottom: 30px !important;
  }
`

export function App() {
  const { setDefaultChain } = useWeb3Modal()

  useAccount({
    onDisconnect() {
      console.log('disconnected')
      // resolves a bug with metamask
      // where if a user disconnects through metamask but not the UI
      // they are unable to connect again
      const userId = localStorage.getItem(api.USER_ID_LOCAL_STORAGE_KEY)
      localStorage.clear()
      if (userId) {
        localStorage.setItem(api.USER_ID_LOCAL_STORAGE_KEY, userId)
      }
      window.location.reload()
    },
  })

  useEffect(() => {
    if (setDefaultChain) {
      setDefaultChain(getDefaultChain())
    }
  }, [setDefaultChain])

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={RECAPTCHA_V3_SITE_KEY}
      useRecaptchaNet
      container={{
        // optional to render inside custom element
        element: 'reCaptchaBadge',
        parameters: {
          badge: 'bottomleft', // optional, default undefined
        },
      }}
    >
      <React.StrictMode>
        <Providers>
          <GlobalStyles />
          <Navbar />
          <PageRoutes />
          <ReCaptchaBadge id='reCaptchaBadge' display />
          <Footer />
        </Providers>
        <Web3Modal projectId={WALLET_CONNECT_PROJECT_ID} ethereumClient={ethereumClient} />
      </React.StrictMode>
    </GoogleReCaptchaProvider>
  )
}

const ReCaptchaBadge = styled.div<{ display: boolean }>`
  ${(props) =>
    !props.display &&
    css`
      display: none;
    `}
`
