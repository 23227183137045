const REASON_REGEX = 'reason="(.*?)"'
const MESSAGE_REGEX = '\\"message\\":\\"(.*?)"'

export function parseBlockchainError(error: Error) {
  const reasonMatch = error.message.match(REASON_REGEX)
  if (reasonMatch) {
    return reasonMatch[1]
  }

  const messageMatch = error.message.match(MESSAGE_REGEX)
  if (messageMatch) {
    return messageMatch[1]
  }

  return error.message
}
