export const API_ENDPOINT = {
  POST: {
    generateImage: '/v1/dogelon/image',
    signature: '/v1/dogelon/signature',
    cancelImageByUserId: '/v1/dogelon/cancelImageByUserId',
    updateImage: '/v1/dogelon/updateImage',
    updateImageSignature: '/v1/dogelon/updateImageSignature',
  },
  GET: {
    generateImageStatus: '/v1/dogelon/image',
    merkleRoot: '/v1/nftWhitelist/root',
    merkleProof: '/v1/nftWhitelist/proof',
    latestId: '/v1/dogelon/latestId',
    latestMints: '/v1/dogelon/latestMints',
    nfts: '/v1/nfts',
    contractState: '/v1/dogelon/contractState',
  },
}
