import { ApiErrorResponse } from '../interfaces'

interface FetchOptions {
  method: 'GET' | 'POST'
  body?: any
}

export async function apiFetch(url: string, options?: FetchOptions) {
  try {
    const response = await fetch(url, {
      method: options?.method ?? 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      body: options?.body,
    })

    return response
  } catch (error) {
    console.error('network error', error)
    const message: ApiErrorResponse = {
      name: 'NetworkError',
      message: 'Connection Issue',
    }
    return {
      status: 500,
      json: () => new Promise((resolve) => resolve(message)),
    } as Response
  }
}
