export const ROUTES = {
  home: '/',
  generate: '/',
  updateImage: '/update',
  confirmUpdateImage: '/confirm',
  mint: '/mint',
  botMint: '/bot-mint',
  embeddedImage: '/i/:image',
}

export const ORDER_SEARCH_PARAMS = {
  orderId: 'id',
  imageFileName: 'image-file-name',
  selectedNFTTokenId: 'token-id',
}
