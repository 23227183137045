import { useEffect, useRef, useState } from 'react'
import { useWeb3Modal } from '@web3modal/react'
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom'
import { useAccount, useNetwork } from 'wagmi'
import styled, { css } from 'styled-components/macro'

import { api, nfts, shared } from '@packages/ui'
import { CompletedOrder, NFT, UpdateImageRequest } from '@packages/interfaces'
import { Connect } from '@packages/web3'

import { COLORS, FONTS } from 'src/shared/constants'
import {
  API_URL,
  DEPLOYED_CHAIN_ID,
  ERC721_CONTRACT_ADDRESS,
  LINKS,
  MINT_STARTED,
  NFT_BASE_URL,
  RECAPTCHA_V2_SITE_KEY,
} from 'src/settings'
import { API_ENDPOINT } from 'src/apiEndpoints'
import {
  OrderReceiptModal,
  OrderErrorModal,
  CancelOrderModal,
  useERC721ContractState,
} from 'src/orders'
import { ORDER_SEARCH_PARAMS, ROUTES } from 'src/pages'
import { Button } from 'src/shared/components'

import { UpdateImageModal } from './UpdateImageModal'
import { ImageUpdateSession } from '../interfaces'

const { BREAKPOINTS, Z_INDEX, Loading } = shared

export function UpdateImageForm() {
  const { address } = useAccount()
  const { isOpen: isWeb3ModalOpen } = useWeb3Modal()
  const { chain } = useNetwork()

  const navigate = useNavigate()
  const { width } = shared.useWindowDimensions()
  const [searchParams] = useSearchParams()

  const [selectedNFT, setSelectedNFT] = useState<NFT>()
  const [displayRequiredNFTError, setDisplayRequiredNFTError] = useState(false)

  const { mintingEnabled, imageUpdatesEnabled } = useERC721ContractState()

  const userId = api.useUserId()

  const [displayReCaptchaV2Checkbox, setDisplayReCaptchaV2Checkbox] = useState(false)
  const fetchWithReCaptcha = api.useFetchWithReCaptcha('order')

  const [userPrompt, setUserPrompt] = useState('')
  const [sanitizedUserPrompt, setSanitizedUserPrompt] = useState('')
  const userPromptInputRef = useRef<HTMLInputElement>(null)
  const [displayRequiredPromptError, setDisplayRequiredPromptError] = useState(false)

  const [updateImageRequest, setUpdateImageRequest] = useState<UpdateImageRequest>()
  const [isSubmittingRequest, setIsSubmittingRequest] = useState(false)
  const [imageUpdateSession, setImageUpdateSession] = useState<ImageUpdateSession>()

  const [isReceiptModalOpen, setIsReceiptModalOpen] = useState(false)
  const [hideReceiptModal, setHideReceiptModal] = useState(false)
  const [isCancelOrderModalOpen, setIsCancelOrderModalOpen] = useState(false)
  const [isUpdateImageModalOpen, setIsUpdateImageModalOpen] = useState(false)

  const [updateImageResponse, setUpdateImageResponse] = useState<CompletedOrder>()

  const [error, setError] = useState<api.ApiErrorResponse>()

  const { nfts: usersNFTs, loading: loadingNFTs } = nfts.useNFTs(
    `${API_URL}${API_ENDPOINT.GET.nfts}`,
    ERC721_CONTRACT_ADDRESS,
    true
  )

  useEffect(() => {
    if (location.pathname === ROUTES.confirmUpdateImage) {
      const imageFileName = searchParams.get(ORDER_SEARCH_PARAMS.imageFileName)
      const orderIdStr = searchParams.get(ORDER_SEARCH_PARAMS.orderId)

      const imageUrl = imageFileName ? nfts.getNFTImageUrl(imageFileName, NFT_BASE_URL) : ''
      const imageHash = imageFileName ? nfts.getImageHash(imageFileName) : undefined
      if (imageHash && orderIdStr && !isNaN(parseInt(orderIdStr))) {
        const orderId = parseInt(orderIdStr)
        if (!updateImageResponse || updateImageResponse.id !== orderId) {
          setUpdateImageResponse({
            id: orderId,
            uncompressedImageUrl: imageUrl,
            finalImageUrl: imageUrl,
            finalImageHash: imageHash,
            completedAtMs: Date.now(),
          })
        }
      }
      setIsUpdateImageModalOpen(true)
    }
  }, [location.pathname])

  useEffect(() => {
    const tokenIdStr = searchParams.get(ORDER_SEARCH_PARAMS.selectedNFTTokenId)
    if (usersNFTs && tokenIdStr && !isNaN(parseInt(tokenIdStr))) {
      const tokenId = parseInt(tokenIdStr)
      const foundNFT = usersNFTs.find((nft) => nft.tokenId === tokenId)
      setSelectedNFT(foundNFT)
    }
  }, [loadingNFTs, usersNFTs])

  useEffect(() => {
    if (isUpdateImageModalOpen || isCancelOrderModalOpen || error) {
      setHideReceiptModal(true)
    }
  }, [isUpdateImageModalOpen, isCancelOrderModalOpen, error])

  function resetData() {
    navigate({
      pathname: ROUTES.updateImage,
    })
    setUpdateImageRequest(undefined)
    setImageUpdateSession(undefined)
    setUpdateImageResponse(undefined)
    setError(undefined)
    setDisplayRequiredPromptError(false)
  }

  function closeUpdateImageModal() {
    resetData()
    // close receipt modal as well since its behind it
    setIsReceiptModalOpen(false)
    setIsUpdateImageModalOpen(false)
  }

  async function onReorder() {
    resetData()
    setIsUpdateImageModalOpen(false)
    focusOnInputs()
  }

  function closeReceiptModal() {
    resetData()
    setIsReceiptModalOpen(false)
  }

  function openReceiptModal() {
    setHideReceiptModal(false)
    setIsReceiptModalOpen(true)
  }

  function closeErrorModal() {
    // close receipt modal as well since its behind it
    setIsReceiptModalOpen(false)
    setError(undefined)
  }

  function closeCancelOrderModal() {
    setIsCancelOrderModalOpen(false)
  }

  function focusOnInputs() {
    userPromptInputRef.current?.focus()
  }

  function onError(error: api.ApiErrorResponse) {
    setError(error)
  }

  function onThumbnailClick(nft: NFT) {
    setSelectedNFT(nft)
    setDisplayRequiredNFTError(false)
  }

  function isGenerateButtonDisabled(): boolean {
    if (!userPrompt || sanitizedUserPrompt.length === 0) {
      setDisplayRequiredPromptError(true)
      focusOnInputs()
      return true
    }
    if (!selectedNFT) {
      setDisplayRequiredNFTError(true)
      return true
    }
    if (isSubmittingRequest || displayReCaptchaV2Checkbox) {
      return true
    }

    return false
  }

  function onCompletedOrder(completedOrder: CompletedOrder) {
    navigate({
      pathname: ROUTES.confirmUpdateImage,
      search: `?${createSearchParams({
        [ORDER_SEARCH_PARAMS.orderId]: `${completedOrder.id}`,
        [ORDER_SEARCH_PARAMS.imageFileName]: nfts.getImageFileName(
          completedOrder.uncompressedImageUrl
        ),
        [ORDER_SEARCH_PARAMS.selectedNFTTokenId]: `${selectedNFT?.tokenId}`,
      })}`,
    })
    setUpdateImageResponse(completedOrder)
    setIsUpdateImageModalOpen(true)
  }

  async function onReCaptchaV2CheckboxChange(token: string | null) {
    if (token) {
      setDisplayReCaptchaV2Checkbox(false)
      if (updateImageRequest) {
        setIsSubmittingRequest(true)
        // delay submission so that we dont get rate limited
        await new Promise((r) => setTimeout(r, 2000))
        await submitRequest(updateImageRequest, token)
      }
    }
  }

  async function submitRequest(request: UpdateImageRequest, reCaptchaV2Token?: string) {
    try {
      setIsSubmittingRequest(true)
      const now = Date.now()

      const response = await fetchWithReCaptcha(
        API_URL + API_ENDPOINT.POST.updateImage,
        request,
        reCaptchaV2Token
      )
      const json = await response.json()

      if (response.status === 200) {
        setImageUpdateSession({
          ...json,
          userPrompt,
          timestamp: now,
        })
        setUpdateImageRequest(undefined)
        openReceiptModal()
      } else if (json && api.isApiErrorResponse(json)) {
        if (json.name === 'ReCaptchaV3FailedError') {
          setDisplayReCaptchaV2Checkbox(true)
        } else if (json.name === 'OrderInProgressError') {
          setIsCancelOrderModalOpen(true)
        } else {
          onError(json)
        }
      } else {
        throw new Error(json)
      }
    } catch (error) {
      console.error(error)
      onError({ name: 'UnknownError' })
    } finally {
      setIsSubmittingRequest(false)
    }
  }

  async function createRequest(event?: React.SyntheticEvent) {
    event?.preventDefault()

    if (isGenerateButtonDisabled()) {
      return
    }

    resetData()
    const request: UpdateImageRequest = {
      userId,
      userPrompt,
      imageHash: selectedNFT?.imageHash ?? '',
      ...(address && chain?.id === DEPLOYED_CHAIN_ID && { address }),
    }
    setUpdateImageRequest(request)
    await submitRequest(request)
  }

  const disableButton = !!(!imageUpdatesEnabled && mintingEnabled && MINT_STARTED)

  return (
    <Container>
      {usersNFTs.length > 0 ? (
        <>
          <NFTContainer>
            <Title>Update NFT Image</Title>
            <Description>
              This action will be change the metadata of the selected token and require Dogelon
              ($ELON) tokens to be burned. Each subsequent image update will cost more tokens.
            </Description>
            {/* <Notice>
              * Metadata updates may take some time to propagate to this page. If you're still
              seeing an older image after an update, please wait a few minutes then refresh this
              page
            </Notice> */}
            <Thumbnails>
              {usersNFTs.map((nft) => {
                return (
                  <NFTThumbnailWrapper
                    nft={{ ...nft, title: nft.title.split('Dogelon AI: Generation')[1] }}
                    width={width > parseInt(BREAKPOINTS.medium) ? 180 : 110}
                    selected={selectedNFT?.tokenId === nft.tokenId}
                    onClick={() => onThumbnailClick(nft)}
                    externalUrl={nfts.getOpenSeaNFTUrl(
                      chain?.id ?? 1,
                      nft?.tokenId ?? 0,
                      ERC721_CONTRACT_ADDRESS
                    )}
                    key={nft.tokenId}
                  />
                )
              })}
            </Thumbnails>
            {displayRequiredNFTError && <ErrorContainer>Please select an NFT</ErrorContainer>}
          </NFTContainer>
          <UserPromptInput
            userPrompt={userPrompt}
            setUserPrompt={setUserPrompt}
            setSanitizedUserPrompt={setSanitizedUserPrompt}
            userPromptInputRef={userPromptInputRef}
            displayRequiredPromptError={displayRequiredPromptError}
            setDisplayRequiredPromptError={setDisplayRequiredPromptError}
            displayReCaptchaV2Checkbox={displayReCaptchaV2Checkbox}
            onReCaptchaV2CheckboxChange={onReCaptchaV2CheckboxChange}
            reCaptchaV2SiteKey={RECAPTCHA_V2_SITE_KEY}
            onSubmit={createRequest}
            isSubmitting={isSubmittingRequest}
            descriptions={{
              noun: 'Dogelon',
              promptRequiredError: 'Please enter a message',
            }}
            placeholder='Input transmission message'
          />
          <ConstructButton
            loading={isSubmittingRequest}
            onClick={createRequest}
            disabled={disableButton}
          >
            {disableButton ? 'Not enabled yet' : 'reconstruct'}
          </ConstructButton>
        </>
      ) : !address ? (
        <NFTContainer>
          <Title>Update Generation</Title>
          <Description>Connect your wallet to update your Generations</Description>
          <ConnectWrapper />
        </NFTContainer>
      ) : loadingNFTs ? (
        <NFTContainer>
          <Title>Update Generation</Title>
          <Loading />
          <Description>Loading Your Generations...</Description>
        </NFTContainer>
      ) : (
        <NFTContainer>
          <Title>Update Generation</Title>
          <Description>You do not have any Generations to update.</Description>
          <ButtonsContainer>
            <Link href={LINKS.opensea} target='_blank' rel='noreferrer'>
              <LargeButton border>Buy some on OpenSea</LargeButton>
            </Link>
          </ButtonsContainer>
        </NFTContainer>
      )}

      <ReceiptModalWrapper
        hideModalElement={hideReceiptModal}
        isModalOpen={isReceiptModalOpen && !isWeb3ModalOpen}
        closeModal={closeReceiptModal}
        orderSession={imageUpdateSession}
        onCompletedOrder={onCompletedOrder}
        onError={onError}
      />
      {updateImageResponse && selectedNFT && (
        <UpdateImageModal
          isModalOpen={isUpdateImageModalOpen && !isWeb3ModalOpen}
          closeModal={closeUpdateImageModal}
          selectedNFT={selectedNFT}
          updateImageResponse={updateImageResponse}
        />
      )}
      {error && (
        <OrderErrorModal
          isModalOpen={true} // the error modal should always be open if an error is set
          closeModal={closeErrorModal}
          orderId={imageUpdateSession?.orderId}
          error={error}
          onReorder={onReorder}
          displayOverlay={!isReceiptModalOpen}
        />
      )}
      <CancelOrderModal
        apiEndpoint={API_ENDPOINT.POST.cancelImageByUserId}
        onError={onError}
        isModalOpen={isCancelOrderModalOpen}
        closeModal={closeCancelOrderModal}
      />
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  width: 100%;
  max-width: ${BREAKPOINTS.medium};

  // error message
  div:nth-child(3) {
    div {
      padding-bottom: 0px;
      color: ${COLORS.logo};
      font-weight: 500;
    }
  }
`

const NFTContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 15px;
  justify-content: space-between;
  flex: 1;
`

const UserPromptInput = styled(shared.UserPromptInput)`
  justify-content: center;
  form {
    max-width: 800px;
    border-radius: 10px;
    background-color: ${COLORS.inputs};
    border: 1px solid white;
    color: white;
    font-family: Otterco-Regular;
    input {
      color: white;
      text-align: center;
      &::placeholder {
        font-weight: 700;
        letter-spacing: 1.5px;
      }
    }
    textarea {
      text-align: center;
      color: white;
      text-indent: 0px;
      &::placeholder {
        font-weight: 700;
        letter-spacing: 1.5px;
      }
    }

    > div {
      // noun
      > div:nth-child(1) {
        display: none;
      }

      border-radius: 10px;
      background-color: ${COLORS.inputs};
      div:first-child {
        background-color: ${COLORS.inputs};
      }
    }
  }
`

const ErrorContainer = styled.div`
  font-weight: 700;
  color: #a70707;
  padding-bottom: 15px;
`

const ConnectWrapper = styled(Connect)`
  button {
    font-size: 20px;
    max-width: 400px;
    height: 50px;
    border-radius: 5px;
    background-color: transparent;
    border: 2px solid ${COLORS.buttons.border};
    font-family: ${FONTS.title};
  }
`

const ConstructButton = styled(Button)`
  width: 150px;
  height: 50px;
  ${(props) =>
    props.disabled &&
    css`
      width: 200px;
      height: 50px;
      background-color: ${COLORS.background};
      color: grey;
    `}
`

const ReceiptModalWrapper = styled(OrderReceiptModal)<{ hideModalElement: boolean }>`
  ${(props) =>
    props.hideModalElement &&
    css`
      .modalElement {
        opacity: 0%;
        transition: opacity 0.3s;
        transition-delay: ${shared.MODAL_ANIMATION_DURATION};
      }
    `}
`

const Link = styled(shared.A)`
  color: ${COLORS.logo};
`

const LargeButton = styled(Button)`
  max-width: 400px;
`

const Thumbnails = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 15px;
  overflow-y: auto;
  overflow-x: hidden
  padding: 5px;
  color: ${COLORS.modalFont};
  font-family: ${FONTS.text};
  font-size: 19px;
  width: 100%;
  max-height: 340px;
  max-width: ${BREAKPOINTS.medium};
  @media only screen and (max-width: ${BREAKPOINTS.medium}) {
    max-height: 500px;
    gap: 5px;
  }
  &::-webkit-scrollbar {
    width: 10px;
    background: ${COLORS.modalFont};
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: ${COLORS.buttons.border};
    border-radius: 10px;
  }
  scrollbar-width: thin;
  scrollbar-color: green transparent;
`

const NFTThumbnailWrapper = styled(nfts.NFTThumbnail)`
  z-index: ${Z_INDEX.content};
  padding: 3px 10px;
  padding-top: 10px;
  ${(props) =>
    props.selected &&
    css`
      border: 3px solid ${COLORS.logo};
    `}
  div {
    border-radius: 10px;
    font-size: 25px;
    line-height: 24px;
    @media only screen and (max-width: ${BREAKPOINTS.medium}) {
      font-size: 22px;
    }
  }
  img {
    border-radius: 6px;
  }

  svg {
    height: 20px;
    width: 20px;
  }
`

const Title = styled(shared.Title)`
  font-family: ${FONTS.title};
  font-size: 40px;
  color: ${COLORS.white};
  line-height: 40px;
  @media only screen and (max-width: ${BREAKPOINTS.medium}) {
    font-size: 25px;
    line-height: 25px;
  }
`

const Description = styled.div`
  color: ${COLORS.white};
  font-family: ${FONTS.title};
  font-size: 20px;
  @media only screen and (max-width: ${BREAKPOINTS.medium}) {
    font-size: 16px;
    line-height: 18px;
  }
`

const Notice = styled(Description)`
  font-size: 15px;
  @media only screen and (max-width: ${BREAKPOINTS.medium}) {
    font-size: 13px;
    line-height: 13px;
  }
`

const ButtonsContainer = styled.div`
  margin-top: 10px;
  gap: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 400px;
  @media only screen and (max-width: ${BREAKPOINTS.medium}) {
    gap: 10px;
  }
`
