import { useCallback, useEffect, useState } from 'react'

import { shared } from '@packages/ui'
import { HydratedMint } from '@packages/interfaces'

const LATEST_MINTS_POLLING_INTERVAL_MS = 30000

export function useLatestMints(apiUrl: string, max = 20) {
  const [latestMints, setLatestMints] = useState<HydratedMint[]>([])

  useEffect(() => {
    getLatestMints()
  }, [])

  shared.useInterval(async () => {
    await getLatestMints()
  }, LATEST_MINTS_POLLING_INTERVAL_MS)

  const getLatestMints = useCallback(async () => {
    try {
      const response = await fetch(`${apiUrl}`)
      const latestMintsResponse: HydratedMint[] = await response.json()
      if (response.ok) {
        if (latestMintsResponse) {
          setLatestMints(latestMintsResponse)
        }
      }
    } catch (error) {
      console.error(error)
    }
  }, [setLatestMints])

  return latestMints.slice(0, max)
}
