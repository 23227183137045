import styled from 'styled-components'

import { StyledComponentProps } from '../interfaces'

interface Props extends StyledComponentProps {
  size?: number
}

export function Loading({ className }: Props) {
  return <LoadingIcon className={className} />
}

const LoadingIcon = styled.div`
  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }

  border-radius: 50%;
  width: 25px;
  height: 25px;
  border: 4px solid rgb(210, 210, 210, 0.2);
  border-top-color: white;
  animation: spin 1s infinite linear;
`
