import { Route, Routes } from 'react-router-dom'

import { ROUTES } from './constants'
import { HomePage } from './HomePage'
import { EmbeddedImagePage } from './EmbeddedImagePage'
import { ScrollToTop } from './components'
import { BotMintPage } from './BotMintPage'
import { UpdateImagePage } from './UpdateImagePage'

export function PageRoutes() {
  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route path={ROUTES.home} element={<HomePage />} />
        <Route path={ROUTES.generate} element={<HomePage />} />
        <Route path={ROUTES.updateImage} element={<UpdateImagePage />} />
        <Route path={ROUTES.confirmUpdateImage} element={<UpdateImagePage />} />
        <Route path={ROUTES.mint} element={<HomePage />} />
        <Route path={ROUTES.botMint} element={<BotMintPage />} />
        <Route path={ROUTES.embeddedImage} element={<EmbeddedImagePage />} />
        <Route path='/*' element={<HomePage />} />
      </Routes>
    </>
  )
}
