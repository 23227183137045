import styled from 'styled-components/macro'

import { Z_INDEX } from '@packages/ui/src/shared'

import { FONTS } from 'src/shared/constants'

export function Footer() {
  return <Container>© DogelonMars 2023</Container>
}

const Container = styled.div`
  margin: 0 auto;
  color: rgba(256, 256, 256, 0.2);
  position: fixed;
  bottom: 3px;
  left: 6px;
  font-family: ${FONTS.title};
  z-index: ${Z_INDEX.content};
`
