import styled from 'styled-components/macro'

import { shared } from '@packages/ui'

import { UpdateImageForm } from 'src/update-image'

import { HomePageWrapper } from './HomePage'

const { BREAKPOINTS } = shared

export function UpdateImagePage() {
  return (
    <HomePageWrapper>
      <UpdateImageContainer>
        <UpdateImageForm />
      </UpdateImageContainer>
    </HomePageWrapper>
  )
}

const UpdateImageContainer = styled.div`
  width: 100%;
  padding: 0px 20px;
  margin-top: 40px;
  padding-bottom: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media only screen and (max-width: ${BREAKPOINTS.medium}) {
    margin-top: 20px;
    margin-bottom: 20px;
  }
`
