import styled, { css } from 'styled-components/macro'
import { useLocation, useSearchParams, createSearchParams, useNavigate } from 'react-router-dom'
import { useRef, useState, useEffect } from 'react'
import { useAccount, useNetwork } from 'wagmi'
import { useWeb3Modal } from '@web3modal/react'

import { shared, api, nfts } from '@packages/ui'
import { CompletedOrder, SignatureData } from '@packages/interfaces'
import { Z_INDEX, hexToRGB } from '@packages/ui/src/shared'

import {
  API_URL,
  DEPLOYED_CHAIN_ID,
  NFT_BASE_URL,
  RECAPTCHA_V2_SITE_KEY,
  SHOW_MAINTENANCE_NOTICE,
} from 'src/settings'
import { ORDER_SEARCH_PARAMS, ROUTES } from 'src/pages'
import { API_ENDPOINT } from 'src/apiEndpoints'
import { COLORS, FONTS } from 'src/shared/constants'
import TitleLogo from 'src/shared/assets/title-logo.png'
import { Button } from 'src/shared/components'

import { OrderRequest, OrderSession } from '../interfaces'
import { OrderReceiptModal } from './OrderReceiptModal'
import { OrderMintingModal } from './OrderMintingModal'
import { OrderErrorModal } from './OrderErrorModal'
import { CancelOrderModal } from './CancelOrderModal'
import { ReactComponent as CircleBGImg } from '../assets/circle-bg.svg'
import { useERC721ContractState } from '../hooks'

const { BREAKPOINTS } = shared

interface Props {
  announcementDisplayed?: boolean
}

export function UserPromptForm({ announcementDisplayed = false }: Props) {
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const userId = api.useUserId()
  const [displayReCaptchaV2Checkbox, setDisplayReCaptchaV2Checkbox] = useState(false)
  const fetchWithReCaptcha = api.useFetchWithReCaptcha('order')

  const { address } = useAccount()
  const { chain } = useNetwork()
  const { isOpen: isWeb3ModalOpen } = useWeb3Modal()

  const { maxSupply, tokenId } = useERC721ContractState()

  const [userPrompt, setUserPrompt] = useState('')
  const [sanitizedUserPrompt, setSanitizedUserPrompt] = useState('')
  const userPromptInputRef = useRef<HTMLInputElement>(null)
  const [displayRequiredPromptError, setDisplayRequiredPromptError] = useState(false)

  const [orderRequest, setOrderRequest] = useState<OrderRequest>()
  const [isSubmittingOrder, setIsSubmittingOrder] = useState(false)
  const [orderSession, setOrderSession] = useState<OrderSession>()
  const [error, setError] = useState<api.ApiErrorResponse>()

  const [completedOrder, setCompletedOrder] = useState<CompletedOrder>()
  const [signatureData, setSignatureData] = useState<SignatureData>()

  const [isReceiptModalOpen, setIsReceiptModalOpen] = useState(false)
  const [hideReceiptModal, setHideReceiptModal] = useState(false)
  const [isMintingModalOpen, setIsMintingModalOpen] = useState(false)
  const [isCancelOrderModalOpen, setIsCancelOrderModalOpen] = useState(false)

  useEffect(() => {
    if (location.pathname === ROUTES.mint) {
      const imageFileName = searchParams.get(ORDER_SEARCH_PARAMS.imageFileName)
      const orderIdStr = searchParams.get(ORDER_SEARCH_PARAMS.orderId)
      const imageUrl = imageFileName ? nfts.getNFTImageUrl(imageFileName, NFT_BASE_URL) : ''
      const imageHash = imageFileName ? nfts.getImageHash(imageFileName) : undefined
      if (imageHash && orderIdStr && !isNaN(parseInt(orderIdStr))) {
        const orderId = parseInt(orderIdStr)
        if (!completedOrder || completedOrder.id !== orderId) {
          setCompletedOrder({
            id: orderId,
            uncompressedImageUrl: imageUrl,
            finalImageUrl: imageUrl,
            finalImageHash: imageHash,
            completedAtMs: Date.now(),
          })
        }
      }
      setIsMintingModalOpen(true)
    }
  }, [location.pathname])

  useEffect(() => {
    if (isMintingModalOpen || isCancelOrderModalOpen || error) {
      setHideReceiptModal(true)
    }
  }, [isMintingModalOpen, isCancelOrderModalOpen, error])

  function resetData() {
    navigate({
      pathname: ROUTES.generate,
    })
    setOrderRequest(undefined)
    setOrderSession(undefined)
    setCompletedOrder(undefined)
    setSignatureData(undefined)
    setError(undefined)
    setDisplayRequiredPromptError(false)
  }

  function focusOnInputs() {
    userPromptInputRef.current?.focus()
  }

  async function onReCaptchaV2CheckboxChange(token: string | null) {
    if (token) {
      setDisplayReCaptchaV2Checkbox(false)
      if (orderRequest) {
        setIsSubmittingOrder(true)
        // delay submission so that we dont get rate limited
        await new Promise((r) => setTimeout(r, 2000))
        await submitOrder(orderRequest, token)
      }
    }
  }

  async function submitOrder(request: OrderRequest, reCaptchaV2Token?: string) {
    try {
      setIsSubmittingOrder(true)
      const now = Date.now()

      const response = await fetchWithReCaptcha(
        API_URL + API_ENDPOINT.POST.generateImage,
        request,
        reCaptchaV2Token
      )
      const json = await response.json()

      if (response.status === 200) {
        setOrderSession({
          ...json,
          userPrompt,
          timestamp: now,
        })
        setOrderRequest(undefined)
        openReceiptModal()
      } else if (json && api.isApiErrorResponse(json)) {
        if (json.name === 'ReCaptchaV3FailedError') {
          setDisplayReCaptchaV2Checkbox(true)
        } else if (json.name === 'OrderInProgressError') {
          setIsCancelOrderModalOpen(true)
        } else {
          onError(json)
        }
      } else {
        throw new Error(json)
      }
    } catch (error) {
      console.error(error)
      onError({ name: 'UnknownError' })
    } finally {
      setIsSubmittingOrder(false)
    }
  }

  async function createOrder(event?: React.SyntheticEvent) {
    event?.preventDefault()

    if (isGenerateButtonDisabled()) {
      focusOnInputs()
      setDisplayRequiredPromptError(true)
      return
    }

    resetData()
    const request: OrderRequest = {
      userId,
      userPrompt,
      ...(address && chain?.id === DEPLOYED_CHAIN_ID && { address }),
    }
    setOrderRequest(request)
    await submitOrder(request)
  }

  function onError(error: api.ApiErrorResponse) {
    setError(error)
  }

  function onCompletedOrder(completedOrder: CompletedOrder, signatureData?: SignatureData) {
    navigate({
      pathname: ROUTES.mint,
      search: `?${createSearchParams({
        [ORDER_SEARCH_PARAMS.orderId]: `${completedOrder.id}`,
        [ORDER_SEARCH_PARAMS.imageFileName]: nfts.getImageFileName(
          completedOrder.uncompressedImageUrl
        ),
      })}`,
    })
    setCompletedOrder(completedOrder)
    setSignatureData(signatureData)
    setIsMintingModalOpen(true)
  }

  async function onReorder() {
    resetData()
    setIsMintingModalOpen(false)
    focusOnInputs()
  }

  function closeReceiptModal() {
    resetData()
    setIsReceiptModalOpen(false)
  }

  function openReceiptModal() {
    setHideReceiptModal(false)
    setIsReceiptModalOpen(true)
  }

  function closeMintingModal() {
    resetData()
    // close receipt modal as well since its behind it
    setIsReceiptModalOpen(false)
    setIsMintingModalOpen(false)
  }

  function closeErrorModal() {
    // close receipt modal as well since its behind it
    setIsReceiptModalOpen(false)
    setError(undefined)
  }

  function closeCancelOrderModal() {
    setIsCancelOrderModalOpen(false)
  }

  function isGenerateButtonDisabled(): boolean {
    if (
      !userPrompt ||
      sanitizedUserPrompt.length === 0 ||
      isSubmittingOrder ||
      displayReCaptchaV2Checkbox
    ) {
      return true
    }

    return false
  }

  return (
    <Container>
      <Logo src={TitleLogo} />
      {SHOW_MAINTENANCE_NOTICE && (
        <NoticeContainer>
          Image generations are currently undergoing maintenance. Please check back later to submit.{' '}
        </NoticeContainer>
      )}
      <UserPromptInput
        announcementDisplayed={announcementDisplayed}
        userPrompt={userPrompt}
        setUserPrompt={setUserPrompt}
        setSanitizedUserPrompt={setSanitizedUserPrompt}
        userPromptInputRef={userPromptInputRef}
        displayRequiredPromptError={displayRequiredPromptError}
        setDisplayRequiredPromptError={setDisplayRequiredPromptError}
        displayReCaptchaV2Checkbox={displayReCaptchaV2Checkbox}
        onReCaptchaV2CheckboxChange={onReCaptchaV2CheckboxChange}
        reCaptchaV2SiteKey={RECAPTCHA_V2_SITE_KEY}
        onSubmit={createOrder}
        isSubmitting={isSubmittingOrder}
        descriptions={{
          noun: 'Dogelon',
          promptRequiredError: 'Please enter a message',
        }}
        placeholder='Input transmission message'
      />

      <ButtonContainer>
        <LeftCircles>
          <SmallCircle animationDelay={1} />
          <LargeCircle animationDelay={2} />
          <SmallCircle animationDelay={3} />
        </LeftCircles>
        <ConstructButton
          loading={isSubmittingOrder}
          onClick={createOrder}
          disabled={SHOW_MAINTENANCE_NOTICE}
        >
          construct
        </ConstructButton>
        <RightCircles>
          <SmallCircle animationDelay={2.5} />
          <LargeCircle animationDelay={1.5} />
          <SmallCircle animationDelay={0.5} />
        </RightCircles>
      </ButtonContainer>

      <MintCount>
        {tokenId - 1}/{maxSupply}
      </MintCount>

      <CircleBGAnimation />

      <ReceiptModalWrapper
        hideModalElement={hideReceiptModal}
        isModalOpen={isReceiptModalOpen && !isWeb3ModalOpen}
        closeModal={closeReceiptModal}
        orderSession={orderSession}
        onCompletedOrder={onCompletedOrder}
        onError={onError}
      />
      {completedOrder && (
        <OrderMintingModal
          isModalOpen={isMintingModalOpen && !isWeb3ModalOpen}
          closeModal={closeMintingModal}
          order={completedOrder}
          signatureData={signatureData}
          onReorder={onReorder}
          displayOverlay={!isReceiptModalOpen}
        />
      )}
      {error && (
        <OrderErrorModal
          isModalOpen={true} // the error modal should always be open if an error is set
          closeModal={closeErrorModal}
          orderId={orderSession?.orderId}
          error={error}
          onReorder={onReorder}
          displayOverlay={!isReceiptModalOpen}
        />
      )}
      <CancelOrderModal
        apiEndpoint={API_ENDPOINT.POST.cancelImageByUserId}
        onError={onError}
        isModalOpen={isCancelOrderModalOpen}
        closeModal={closeCancelOrderModal}
      />
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  height: 100%;
  width: 100%;
  max-width: 600px;

  // error message
  div:nth-child(3) {
    div {
      padding-bottom: 0px;
      color: ${COLORS.logo};
      font-weight: 500;
    }
  }

  @media only screen and (max-width: ${BREAKPOINTS.medium}) {
    gap: 15px;
  }
`

const NoticeContainer = styled.div`
  background-color: #e13401;
  border-radius: 4px;
  line-height: 25px;
  padding: 5px;
`

const Logo = styled.img`
  height: 5%;
`

const MintCount = styled.div`
  padding: 15px;
  @media only screen and (max-width: ${BREAKPOINTS.medium}) {
    padding: 20px;
  }
  font-family: ${FONTS.logo};
  background-image: linear-gradient(177deg, #ffffff, #91b3e3);
  background-clip: text;
  background-size: 100%;
  background-repeat: repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  filter: drop-shadow(1.5px 0px 0px ${COLORS.logo}) drop-shadow(-1.5px 0px 0px ${COLORS.logo})
    drop-shadow(0px 1.5px 0px ${COLORS.logo}) drop-shadow(0px -1.5px 0px ${COLORS.logo})
    drop-shadow(1px 0px 1px rgba(255, 255, 255, 0.25))
    drop-shadow(-1px 0px 1px rgba(255, 255, 255, 0.25))
    drop-shadow(0px 1px 1px rgba(255, 255, 255, 0.25))
    drop-shadow(0px -1px 1px rgba(255, 255, 255, 0.25));

  font-size: 30px;
  letter-spacing: 7px;
  line-height: 30px;
  @media only screen and (max-width: ${BREAKPOINTS.medium}) {
    font-size: 25px;
    line-height: 20px;
  }
`

const UserPromptInput = styled(shared.UserPromptInput)<{ announcementDisplayed: boolean }>`
  justify-content: center;
  form {
    max-width: 800px;
    border-radius: 10px;
    background-color: ${COLORS.inputs};
    border: 1px solid white;
    color: white;
    font-family: Otterco-Regular;
    input {
      color: white;
      text-align: center;
      &::placeholder {
        font-weight: 700;
        letter-spacing: 1.5px;
      }
    }
    textarea {
      text-align: center;
      color: white;
      text-indent: 0px;
      &::placeholder {
        font-weight: 700;
        letter-spacing: 1.5px;
      }
    }

    > div {
      // noun
      > div:nth-child(1) {
        display: none;
      }

      border-radius: 10px;
      background-color: ${COLORS.inputs};
      div:first-child {
        background-color: ${COLORS.inputs};
      }
    }
  }
`

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 20px;
  margin-bottom: 10px;
`

const LeftCircles = styled.div`
  div:nth-child(1) {
    position: relative;
    top: 28px;
    left: -15px;
  }
  div:nth-child(2) {
    position: relative;
    top: 49px;
    left: -10px;
  }
  div:nth-child(3) {
    position: relative;
    top: 42px;
    left: 19px;
  }
`

const RightCircles = styled.div`
  div:nth-child(1) {
    position: relative;
    top: -23px;
    left: -8px;
  }
  div:nth-child(2) {
    position: relative;
    top: -25px;
    left: 18px;
  }
  div:nth-child(3) {
    position: relative;
    top: -15px;
    left: 16px;
  }
`

const Circle = styled.div<{ animationDelay: number }>`
  border-radius: 50px;
  background-color: ${COLORS.buttons.background};

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: ${COLORS.buttons.background};
    border-radius: 15px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
    animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955)
      ${(props) => props.animationDelay}s infinite;
  }

  @keyframes pulse-dot {
    0% {
      transform: scale(0.6);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(0.6);
    }
  }
`

const LargeCircle = styled(Circle)`
  width: 15px;
  height: 15px;
`

const SmallCircle = styled(Circle)`
  width: 8px;
  height: 8px;
`

const ConstructButton = styled(Button)`
  width: 150px;
  height: 50px;
`

const CircleBGAnimation = styled(CircleBGImg)`
  position: fixed;
  top: 1vw;
  z-index: ${Z_INDEX.background};
  max-width: 600px;

  @media only screen and (max-width: ${BREAKPOINTS.medium}) {
    top: 10vw;
  }

  .st0,
  .st1,
  .st2,
  .st3 {
    fill: none;
    stroke: ${hexToRGB(COLORS.buttons.background, 0.25)};
    stroke-width: 42;
  }

  .st0 {
  }

  .st1 {
    stroke-width: 16;
    stroke-dasharray: 12.1947, 12.1947, 12.1947, 12.1947, 12.1947, 12.1947;
  }

  .st2 {
    stroke-width: 26;
    stroke-linecap: butt;
    stroke: ${hexToRGB(COLORS.buttons.background, 0.25)};
    stroke-dasharray: 72;
  }

  .st3 {
  }
`

const ReceiptModalWrapper = styled(OrderReceiptModal)<{ hideModalElement: boolean }>`
  ${(props) =>
    props.hideModalElement &&
    css`
      .modalElement {
        opacity: 0%;
        transition: opacity 0.3s;
        transition-delay: ${shared.MODAL_ANIMATION_DURATION};
      }
    `}
`
