import { useCallback } from 'react'

import { RECAPTCHA, RECAPTCHA_VERSION } from '../constants'
import { apiFetch } from '../utils'
import { useV3ReCaptcha } from './useV3ReCaptcha'

export function useFetchWithReCaptcha(actionName: string) {
  const executeV3ReCaptcha = useV3ReCaptcha(actionName)

  const fetchWithReCaptcha = useCallback(
    async (url: string, body: any, reCaptchaV2Token?: string) => {
      let reCaptchaToken = reCaptchaV2Token
      let reCaptchaVersion = RECAPTCHA_VERSION.v2
      if (!reCaptchaToken) {
        reCaptchaToken = await executeV3ReCaptcha()
        reCaptchaVersion = RECAPTCHA_VERSION.v3
      }

      const response = await apiFetch(url, {
        method: 'POST',
        body: JSON.stringify({
          ...body,
          [RECAPTCHA.tokenKey]: reCaptchaToken,
          [RECAPTCHA.versionKey]: reCaptchaVersion,
        }),
      })

      return response
    },
    [executeV3ReCaptcha]
  )

  return fetchWithReCaptcha
}
