import { css } from 'styled-components/macro'

export const getImageLoadingAnimation = (
  color1Hex: string,
  color2Hex: string,
  color3Hex: string
) => css`
  @property --rotate {
    syntax: '<angle>';
    initial-value: 132deg;
    inherits: false;
  }

  background: none;
  width: 100%;
  height: 100%;
  padding: 3px;
  position: relative;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
  font-size: 1.5em;
  color: rgb(88 199 250 / 0%);
  cursor: pointer;
  font-family: cursive;

  &::before {
    content: '';
    border-radius: 8px;
    background-image: linear-gradient(var(--rotate), ${color1Hex}, ${color2Hex} 43%, ${color3Hex});
    position: absolute;
    z-index: -1;
    top: -1%;
    left: -2%;
    animation: spin 2.5s linear infinite;
  }

  &::after {
    position: absolute;
    content: '';
    top: 12%;
    left: 0;
    right: 0;
    z-index: -1;
    height: 100%;
    width: 100%;
    margin: 0 auto;
    transform: scale(0.8);
    filter: blur(100px);
    background-image: linear-gradient(var(--rotate), ${color1Hex}, ${color2Hex} 43%, ${color3Hex});
    opacity: 1;
    transition: opacity 0.5s;
    animation: spin 2.5s linear infinite;
  }

  @keyframes spin {
    0% {
      --rotate: 0deg;
    }
    100% {
      --rotate: 360deg;
    }
  }
`
