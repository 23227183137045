import styled from 'styled-components'
import { ContractTransactionResponse } from 'ethers'

import {
  BREAKPOINTS,
  StyledComponentProps,
  A,
  Button,
  ExpandArea,
  Title as _Title,
} from '@packages/ui/src/shared'

interface Props extends StyledComponentProps {
  txError?: string
  tx?: ContractTransactionResponse
  onTryAgain?: () => void
}

export function TransactionError({ txError, tx, onTryAgain, children, className }: Props) {
  return (
    <Container className={className}>
      {children}
      <P>The following error occurred while processing your transaction:</P>
      <ExpandArea linkText='error' useAnimation={false}>
        {txError}
      </ExpandArea>
      <ButtonsContainer>
        {tx?.hash && (
          <A href={`https://etherscan.io/tx/${tx.hash}`} target='_blank' rel='noreferrer'>
            <Button>View on Etherscan</Button>
          </A>
        )}
        <Button onClick={onTryAgain} border>
          Try Again?
        </Button>
      </ButtonsContainer>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
`

const P = styled.p<{ bold?: boolean; center?: boolean }>`
  width: 100%;
  font-size: 16px;
  ${({ bold }) => (bold ? 'font-weight: bold' : '')};
  ${({ center }) => (center ? 'text-align: center' : '')};
`

const ButtonsContainer = styled.div`
  gap: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 400px;
  @media only screen and (max-width: ${BREAKPOINTS.medium}) {
    gap: 10px;
  }
`
