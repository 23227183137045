import styled from 'styled-components'

import { api, shared } from '@packages/ui'

import { ModalButton } from 'src/shared/components'
import { COLORS, FONTS } from 'src/shared/constants'

const { BREAKPOINTS } = shared

export interface Props extends shared.StyledComponentProps {
  orderId?: number
  error: api.ApiErrorResponse
  onReorder?: () => void
  onOrderSomethingElse?: () => void
  onTryAgain?: () => void
}

export function OrderErrorMessage({
  orderId,
  error,
  onReorder,
  onOrderSomethingElse,
  onTryAgain,
  className,
  children,
}: Props) {
  let title: string
  let description: string
  let button: React.ReactNode

  title = 'Transmission Failed'
  description = 'Transmission failed. Please submit a new request.'
  button

  switch (error.name) {
    case 'CustomError':
      title = error?.title ? error.title : title
      description = error?.message ? error.message : description
      break
    case 'OrderExpiredError':
      title = 'Transmission Terminated'
      description = 'Transmission terminated and no longer mintable. Please submit a new request.'
      break
    case 'OrderCancelledError':
      title = 'Transmission Cancelled'
      description = 'Looks like this transmission has been cancelled. Please submit a new request.'
      break
    case 'NotFoundError':
      title = 'Transmission Lost'
      description = 'Transmission lost. Please submit a new request.'
      button = onOrderSomethingElse ? (
        <ModalButton onClick={onOrderSomethingElse}>Retry</ModalButton>
      ) : (
        <></>
      )
      break
    case 'ModelServerError':
      title = 'Transmission Failed'
      description = 'Transmission failed. Please submit a new request.'
      button = onReorder ? <ModalButton onClick={onReorder}>Retry</ModalButton> : <></>
      break
    case 'ReCaptchaV2FailedError':
    case 'ReCaptchaV3FailedError':
      title = 'Captcha Failure'
      description = 'Non-human detected.'
      break
    case 'RateLimitError':
      title = 'Too many requests'
      description = 'Too many requests submitted. Please submit a new request.'
      break
    case 'NetworkError':
      title = 'Connection Issue'
      description = 'Issue establishing connection. Please submit a new request.'
      break
  }

  const canTryAgain = error.name !== 'OrderExpiredError'

  return (
    <Container className={className}>
      <Title>{title}</Title>
      {orderId && <OrderId>Transmission #{orderId}</OrderId>}
      {description && <Description>{description}</Description>}
      <ButtonsContainer>
        {button}{' '}
        {onTryAgain && canTryAgain && <ModalButton onClick={onTryAgain}>Retry</ModalButton>}
      </ButtonsContainer>
      {children}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  justify-contents: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 10px;
  gap: 10px;
  color: ${COLORS.modalFont};
  font-family: ${FONTS.text};
`

const Title = styled(shared.Title)`
  text-align: center;
  color: ${COLORS.modalFont};
  font-family: ${FONTS.text};
  font-size: 50px;
  line-height: 42px;
  @media only screen and (max-width: ${BREAKPOINTS.medium}) {
    font-size: 40px;
    line-height: 31px;
  }
`

const OrderId = styled.div`
  font-size: 32px;
  text-align: center;
  @media only screen and (max-width: ${BREAKPOINTS.medium}) {
    font-size: 18px;
    line-height: 22px;
  }
`

const Description = styled.div`
  font-size: 28px;
  line-height: 28px;
  text-align: center;
  @media only screen and (max-width: ${BREAKPOINTS.medium}) {
    font-size: 25px;
    line-height: 25px;
  }
`

const ButtonsContainer = styled.div`
  gap: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 400px;
  @media only screen and (max-width: ${BREAKPOINTS.medium}) {
    gap: 10px;
  }
`
